import axios from "axios";
import React, { useState, useEffect, useMemo } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes, faCheck, faTrashAlt, faImages, faUserTie } from '@fortawesome/free-solid-svg-icons'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, dateFilter  } from 'react-bootstrap-table2-filter';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

function AllInventories() {

    const [inventoryData, setInventoryData] = useState([]);
    const [inventoryId, setInventoryId] = useState(0);
    const [deleteDisabled, setDeleteDisabled] = useState(false);
    const [inventoryTypes] = React.useState([
        { label: "All", value: "All" },
        { label: "Final Products", value: "FinalProduct" },
        { label: "Row Materials", value: "RowMaterial" }
    ]);
    const [selectedInventoryType, setSelectedInventoryType] = useState("All");

    const handleClose = () => setInventoryId(0);

    useEffect(() => {
        loadInventoryRecords(selectedInventoryType);
    },[]);

    function loadInventoryRecords(inventoryType) {

        var endpointUrl="inventories";

        if (inventoryType == "FinalProduct") {
            endpointUrl = "inventories/finalproducts/true";
        }
        else if (inventoryType == "RowMaterial") {
            endpointUrl = "inventories/finalproducts/false";
        }

        axios.get(process.env.REACT_APP_API_URL + endpointUrl)
        .then(response => 
            {
                setInventoryData(response.data.data);
            }
        )
        .catch(error => {
            if (error.response) {
                if(error.response.data.message != undefined){
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    function deleteInventoryRecord() {
        setDeleteDisabled(true);

        axios.post(process.env.REACT_APP_API_URL + "inventories/delete/" + inventoryId, null)
          .then((response) => {

            if(response != null && response.data.success == true) {
                setInventoryId(0);
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                loadInventoryRecords(selectedInventoryType);
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            setDeleteDisabled(false);

          }).catch(error => { // your error handling goes here};
            setDeleteDisabled(false);
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
          });
    }

    function dateColumnFormatter(cell, row) {
        return <Moment format="DD/MM/YYYY">{row.createdDate}</Moment>;
    }

    function activeColumnFormatter(cell, row) {
        if (row.isActive) {
            return <FontAwesomeIcon title="Active" icon={faCheck} />;
        }
        else{
            return <FontAwesomeIcon title="InActive" icon={faTimes} />;
        }
    }

    function deleteInventoryConfirmation(id) {
        setInventoryId(id);
    }

    function editColumnFormatter(cell, row) {
        return (
            <div>
                <Link title="Update Inventory Details" to={'/inventorydetails/'+ row.inventoryId }><FontAwesomeIcon icon={faEdit} /></Link>
                <a title="Delete Inventory" className="cursor-pointer pl-2" onClick={() => deleteInventoryConfirmation(row.inventoryId)}><FontAwesomeIcon icon={faTrashAlt} /></a>
            </div>
        );
    }

    function productTypeFormatter(cell, row) {
        if (row.isFinalProduct) {
            return "Final Product";
        }
        else{
            return "Row Material";
        }
    }

    function handleInventoryTypeSelect(e) {
        setSelectedInventoryType(e.target.value);

        loadInventoryRecords(e.target.value);
    }

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: true, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: true // Hide the pagination list when only one page
      };

      const columns = [{
        dataField: 'inventoryId',
        text: 'Inventory Id',
        hidden: true
      }, {
        dataField: 'name',
        text: 'Name',
        sort: true,
        filter: textFilter()
      }, {
        dataField: 'quantity',
        text: 'Quantity',
        sort: true,
        filter: textFilter(),
        align: 'center'
      }, {
        dataField: 'purchasePrice',
        text: 'Purc. Price',
        sort: true,
        filter: textFilter(),
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        },
        align: 'right'
      }, {
        dataField: 'purchaseGST',
        text: 'Purc. GST (%)',
        sort: true,
        filter: textFilter(),
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        },
        align: 'center'
      }, {
        dataField: 'sellingPrice',
        text: 'Sell Price',
        sort: true,
        filter: textFilter(),
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        },
        align: 'right'
      }, {
        dataField: 'sellingGST',
        text: 'Sell GST (%)',
        sort: true,
        filter: textFilter(),
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        },
        align: 'center'
      }, {
        dataField: 'isFinalProduct',
        text: 'Type',
        sort: true,
        formatter: productTypeFormatter,
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        }
      }, {
        dataField: 'createdDate',
        text: 'Created Date',
        sort: true,
        formatter: dateColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        }
      }, {
        dataField: 'isActive',
        text: 'Active',
        sort: true,
        align: 'center',
        formatter: activeColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        }
      }, {
        dataField: '',
        text: 'Action',
        align: 'center',
        formatter: editColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        }
      }];

    return (
        <div>
            <ToastContainer></ToastContainer>
            <Modal show={inventoryId > 0} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Confirmation !!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this record?
                </Modal.Body>
                <Modal.Footer>
                <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" disabled={deleteDisabled} onClick={deleteInventoryRecord}>
                    Yes
                </Button>
                <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" onClick={handleClose}>
                    No
                </Button>
                </Modal.Footer>
            </Modal>
            <div className="bg-gray-300 px-2 py-3 border-solid border-gray-400 border-b">
                <b>STOCKS</b>
            </div>
            <div className="p-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-grey-darker text-xs font-light mb-1"
                        for="grid-state">
                    Filter By Stock Type
                </label>
                <div className="relative">
                    <select value={selectedInventoryType} onChange={e => handleInventoryTypeSelect(e)} className="block appearance-none w-full bg-grey-200 border border-grey-200 text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey">
                        {inventoryTypes.map(item => (
                            <option
                            key={item.value}
                            value={item.value}
                            >
                            {item.label}
                            </option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-grey-darker">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="p-3">
                <BootstrapTable keyField='id' data={inventoryData} columns={columns} pagination={ paginationFactory(options) } filter={ filterFactory() } filterPosition="top"  />
            </div>
        </div>
    );
}
export default AllInventories;