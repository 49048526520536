import React, { useState } from "react"
import Dashboard from '../../components/dashboard/dashboard.js';
import Login from '../../components/login/login.js';
import AppConstants from '../../components/common/constants.js';

function Home() {
    
    var isUserLoggedIn = false;
    if (window.sessionStorage.getItem('UserDetails')) {
      var user = JSON.parse(window.sessionStorage.getItem('UserDetails'));
      AppConstants.UserDetails = user;
      isUserLoggedIn = user.isLoggedIn;
    }
    
    if (isUserLoggedIn) {
        return <Dashboard />;
    }
    else{
        return <Login />;
    }
}
export default Home;