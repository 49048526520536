import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../assets/styles/pagination.css';
import axios from "axios";
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes, faCheck, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function AllDealers() {

    const [dealerData, setDealerData] = useState([]);
    const [dealerId, setDealerId] = useState(0);
    const [deleteDisabled, setDeleteDisabled] = useState(false);

    const handleClose = () => setDealerId(0);

    useEffect(() => {
        loadDealerRecords();
    },[]);

    function loadDealerRecords() {
        axios.get(process.env.REACT_APP_API_URL + "dealers")
        .then(response => 
            {
                setDealerData(response.data.data);
            }
        )
        .catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    function deleteDealerConfirmation(id){
        setDealerId(id);
    }

    function deleteDealerRecord () {

        setDeleteDisabled(true);

        axios.post(process.env.REACT_APP_API_URL + "dealers/delete/" + dealerId, null)
          .then((response) => {

            if(response != null && response.data.success == true) {
                setDealerId(0);
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                loadDealerRecords();
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            setDeleteDisabled(false);

          }).catch(error => { // your error handling goes here};
            setDeleteDisabled(false);
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
          });
    }

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: true, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: true // Hide the pagination list when only one page
    };

    function activeColumnFormatter(cell, row) {
        if (row.isActive) {
            return <FontAwesomeIcon title="Active" icon={faCheck} />;
        }
        else{
            return <FontAwesomeIcon title="InActive" icon={faTimes} />;
        }
    }

    function showOnContactColumnFormatter(cell, row) {
        if (row.showOnContact) {
            return <FontAwesomeIcon title="Yes" icon={faCheck} />;
        }
        else{
            return <FontAwesomeIcon title="No" icon={faTimes} />;
        }
    }

    function registeredColumnFormatter(cell, row) {
        if (row.isRegistered) {
            return <FontAwesomeIcon title="Registered" icon={faCheck} />;
        }
        else{
            return <FontAwesomeIcon title="Not Registered" icon={faTimes} />;
        }
    }

    function editColumnFormatter(cell, row) {
        return (
            <div>
                <Link title="Update Dealer Details" to={'/dealerdetails/'+ row.dealerId }><FontAwesomeIcon icon={faEdit} /></Link>
                <a title="Delete Dealer" className="cursor-pointer pl-2" onClick={() => deleteDealerConfirmation(row.dealerId)}><FontAwesomeIcon icon={faTrashAlt} /></a>
            </div>
        );
    }

    const columns = [{
        dataField: 'dealerId',
        text: 'Dealer Id',
        hidden: true
      }, {
        dataField: 'name',
        text: 'Name',
        sort: true,
        filter: textFilter()
      }, {
        dataField: 'phone',
        text: 'Phone',
        sort: true,
        filter: textFilter()
      }, {
        dataField: 'dealerCode',
        text: 'Dealer Code',
        sort: true,
        filter: textFilter()
      }, {
        dataField: 'city',
        text: 'City',
        sort: true,
        filter: textFilter()
      }, {
        dataField: 'state',
        text: 'State',
        sort: true,
        filter: textFilter()
      }, {
        dataField: 'pincode',
        text: 'Pin Code',
        sort: true,
        filter: textFilter()
      }, {
        dataField: 'isRegistered',
        text: 'Registered',
        sort: true,
        align: 'center',
        formatter: registeredColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { width: '150px', textAlign: 'center' };
        }
      }, {
        dataField: 'showOnContact',
        text: 'Show On Contact',
        sort: true,
        align: 'center',
        formatter: showOnContactColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { width: '100px', textAlign: 'center' };
        }
      }, {
        dataField: 'isActive',
        text: 'Active',
        sort: true,
        align: 'center',
        formatter: activeColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { width: '100px', textAlign: 'center' };
        }
      }, {
        dataField: '',
        text: 'Action',
        align: 'center',
        formatter: editColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { width: '100px', textAlign: 'center' };
        }
    }];

    return (
        <div class="mb-2 border-solid border-grey-light rounded border shadow-sm w-full">
            <ToastContainer></ToastContainer>
            <Modal show={dealerId > 0} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Confirmation !!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this record?
                </Modal.Body>
                <Modal.Footer>
                <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" disabled={deleteDisabled} onClick={deleteDealerRecord}>
                    Yes
                </Button>
                <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" onClick={handleClose}>
                    No
                </Button>
                </Modal.Footer>
            </Modal>


            <div class="bg-gray-300 px-2 py-3 border-solid border-gray-400 border-b">
                <b>DEALERS</b>
            </div>
            <div class="p-3">
                <BootstrapTable keyField='id' data={dealerData} columns={columns} pagination={ paginationFactory(options) } filter={ filterFactory() } filterPosition="top"  />
            </div>
        </div>
    );
}
export default AllDealers;