import axios from "axios";
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

function ProductImages() {
    const params = useParams();
    const history = useHistory();
    //https://sankhadip.medium.com/how-to-upload-files-in-net-core-web-api-and-react-36a8fbf5c9e8
    const [productDetails, setProductDetails] = useState({name: '', description: '', isActive: false});
    const [productImages, setProductImages] = useState([{productImageId: null, productId: null, imageType: null, imageName: ''}]);
    const [primaryImage, setPrimaryImage] = useState();
    const [primaryImageName, setPrimaryImageName] = useState();
    const [secondaryImage, setSecondaryImage] = useState();
    const [secondaryImageName, setSecondaryImageName] = useState();
    const [showPrimaryUpload, setShowPrimaryUpload] = useState(false);
    const [showSecondaryUpload, setShowSecondaryUpload] = useState(false);
    const [deleteProductImageId, setDeleteProductImageId] = useState(0);
    const [deleteDisabled, setDeleteDisabled] = useState(false);

    const handleClose = () => setDeleteProductImageId(0);

    const getPrimaryImage = (e) => {
        setShowPrimaryUpload(true);
        setPrimaryImage(e.target.files[0]);
        setPrimaryImageName(e.target.files[0].name);
    };

    const getSecondaryImage = (e) => {
        setShowSecondaryUpload(true);
        setSecondaryImage(e.target.files[0]);
        setSecondaryImageName(e.target.files[0].name);
    };

    function clearPrimaryImageControl() {
        document.getElementById('primaryImage').value = '';
        setShowPrimaryUpload(false);
    }

    function clearSecondaryImageControl() {
        document.getElementById('secondaryImage').value = '';
        setShowSecondaryUpload(false);
    }

    function uploadImage(imageType) {
        const formData = new FormData();

        if(imageType == 1) {
            formData.append("ImageFile",primaryImage);
            formData.append("ImageName",primaryImageName);
        }
        else {
            formData.append("ImageFile",secondaryImage);
            formData.append("ImageName",secondaryImageName);
        }
        
        formData.append("ImageType",imageType);
        formData.append("ProductId",params.productId);

        axios.post(process.env.REACT_APP_API_URL + "productimages/upload", formData)
          .then((response) => {

            if(response != null && response.data != undefined && response.data.success == true) {

                if(imageType == 1) {
                    clearPrimaryImageControl();
                }
                else {
                    clearSecondaryImageControl() 
                }

                getProductImages();
                
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            //setSubmitDisabled(false);

          }).catch(error => { // your error handling goes here};
            //setSubmitDisabled(false);
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            //console.log(error.config);
          });
    }

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "products/" + params.productId)
        .then(response => 
            {
                if(response.data.data) {
                    setProductDetails(response.data.data);
                }
                else
                {
                    history.push("/notfound");
                }
            }
        )
        .catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });

        getProductImages();

    },[]);

    function getProductImages() {
        axios.get(process.env.REACT_APP_API_URL + "productimages/product/" + params.productId)
        .then(response => 
            {
                if(response.data.data) {
                    setProductImages(response.data.data);
                }
            }
        )
        .catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    function deleteProductImageConfirmation(productImageId) {
        setDeleteProductImageId(productImageId);
    }

    function deleteProductImage() {

        setDeleteDisabled(true);

        axios.post(process.env.REACT_APP_API_URL + "productimages/delete/" + deleteProductImageId, null)
          .then((response) => {

            if(response != null && response.data != undefined && response.data.success == true) {

                getProductImages();
                setDeleteProductImageId(0);
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            setDeleteDisabled(false);

          }).catch(error => { // your error handling goes here};
            setDeleteDisabled(false);
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            //console.log(error.config);
          });
    }

    return (
        <div className="flex flex-1  flex-col md:flex-row lg:flex-row mx-2">
            <ToastContainer></ToastContainer>
            <Modal show={deleteProductImageId > 0} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation !!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this image?
                </Modal.Body>
                <Modal.Footer>
                    <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" disabled={deleteDisabled} onClick={deleteProductImage}>
                        Yes
                    </Button>
                    <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" onClick={handleClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="mb-2 border-solid border-gray-300 rounded border shadow-sm w-full">
                <div className="bg-gray-200 px-2 py-3 border-solid border-gray-200 border-b">
                    <b>UPLOAD PRODUCT IMAGES</b>
                </div>
                <div className="p-3">
                    <div className="md:flex md:items-center mb-6">
                        <div className="md:w-1/7">
                            <label className="block text-gray-500 font-regular md:text-right mb-1 md:mb-0 pr-4"
                                    for="inline-full-name">
                                PRODUCT NAME:
                            </label>
                        </div>
                        <div className="md:w-3/4">
                            {productDetails.name}
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 relative">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Primary Image
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="file" accept="image/*" placeholder="Browse Primary Image" name="primaryImage" id="primaryImage" onChange={getPrimaryImage}></input>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 relative">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                &nbsp;
                            </label>

                            <button className="md:mt-2 flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded ml-2"
                                type="button" onClick={() => uploadImage(1)} style={{display: showPrimaryUpload ? 'inline' : 'none'}}>
                                Upload
                            </button>
                            <button className="md:mt-2 flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded ml-2"
                                type="button" onClick={clearPrimaryImageControl} style={{display: showPrimaryUpload ? 'inline' : 'none'}}>
                                Clear
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <ul>
                            {productImages.map((productImage, index) => (  
                                productImage.imageType == 1 ? (<li style={{float: 'left'}}>
                                        <div className="sm:w-64 rounded overflow-hidden shadow-lg mb-6">
                                            <div className="px-6" style={{height: "200px"}}>
                                                <img height="200" width="200" src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "productimages/" + productImage.imageName}></img>
                                            </div>
                                            <div className="mx-8 py-4 float-right">
                                                <a className="cursor-pointer" onClick={ () => deleteProductImageConfirmation(productImage.productImageId)}>
                                                    <FontAwesomeIcon title="Delete Image" icon={faTrashAlt} />
                                                </a>
                                            </div>
                                        </div>
                                    </li>) : null
                            ))}
                        </ul>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 relative">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Secondary Image
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="file" accept="image/*" placeholder="Browse Secondary Image" name="secondaryImage" id="secondaryImage" onChange={getSecondaryImage}></input>
                        </div>
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 relative">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                &nbsp;
                            </label>

                            <button className="md:mt-2 flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded ml-2"
                                type="button" onClick={() => uploadImage(2)} style={{display: showSecondaryUpload ? 'inline' : 'none'}}>
                                Upload
                            </button>
                            <button className="md:mt-2 flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded ml-2"
                                type="button" onClick={clearSecondaryImageControl} style={{display: showSecondaryUpload ? 'inline' : 'none'}}>
                                Clear
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <ul>
                            {productImages.map((productImage, index) => (  
                                productImage.imageType == 2 ? (<li style={{float: 'left'}}>
                                <div className="sm:w-64 rounded overflow-hidden shadow-lg mb-6">
                                    <div className="px-6" style={{height: "200px"}}>
                                        <img height="200" width="200" src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "productimages/" + productImage.imageName}></img>
                                    </div>
                                    <div className="mx-8 py-4 float-right">
                                        <a className="cursor-pointer" onClick={ () => deleteProductImageConfirmation(productImage.productImageId)}>
                                            <FontAwesomeIcon title="Delete Image" icon={faTrashAlt} />
                                        </a>
                                    </div>
                                </div>
                                </li>) : null
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductImages;