import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons'
import React from 'react';
import AppConstants from '../../components/common/constants.js';
import { useHistory } from "react-router-dom";

function Header() {
    const history = useHistory();

    function userLogout() {
        sessionStorage.removeItem('UserDetails');
        history.push("/");
        window.location.reload();
    }

    return (
        <header className="bg-nav">
            <div className="flex justify-between">
                <div className="p-1 mx-3 inline-flex items-center">
                    <FontAwesomeIcon icon={faAlignJustify} className="text-white" />
                    <h1 className="text-white p-2">GREEN FARM SOLUTIONS ADMINISTRATION</h1>
                </div>
                <div className="p-1 flex flex-row items-center">
                    <a className="text-white p-2 no-underline hidden md:block lg:block">Welcome {AppConstants.UserDetails?.name} !</a>
                    <a onClick={userLogout} className="text-white p-2 no-underline hidden md:block lg:block cursor-pointer">Logout</a>
                </div>
            </div>
        </header>
    );
}
export default Header;