import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <div>
            <span>404 page not found.</span>
            <span><Link to="/">Back to Home Page</Link></span>
        </div>
    );
}
export default NotFound;