import axios from "axios";
import React, { useState, useEffect, useMemo } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faRupeeSign } from '@fortawesome/free-solid-svg-icons'

function NewInventory() {
    const history = useHistory();
    const [inventoryDetails, setInventoryDetails] = useState({name: '', description: '', isFinalProduct: false, purchasePrice: '', purchaseGST: 0, sellingPrice: '',sellingGST: 0,quantity: '', isActive: true});
    const [quantityUsed, setQuantityUsed] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [addRowMaterialtoArrayDisabled, setAddRowMaterialtoArrayDisabled] = useState(true);
    const [applyRowMaterials, setApplyRowMaterials] = useState(false);
    const [tempRowMaterialsOfFinalProduct, setTempRowMaterialsOfFinalProduct] = useState([]);
    const [rowMaterialsOfFinalProduct, setRowMaterialsOfFinalProduct] = useState(tempRowMaterialsOfFinalProduct);
    
    const [rowMaterials, setRowMaterials] = React.useState([]);
    const [selectedRowMaterial, setSelectedRowMaterial] = useState("");
    
    const handleChange = e => {
        const { name, value, checked } = e.target;

        setInventoryDetails(prevState => ({
            ...prevState,
            [name]: e.target.type === "checkbox" ? checked : value 
        }));

        if (name != undefined && name == 'isFinalProduct') {
            if (checked != undefined && checked == true) {
                setApplyRowMaterials(true);
                loadRowMaterials();
            }
        }
    };

    const handleUsedQuantityChange = e => {
        const { name, value, checked } = e.target;

        setQuantityUsed(e.target.value);
    };

    useEffect(() => {
        setRowMaterialsOfFinalProduct(tempRowMaterialsOfFinalProduct);
    }, [tempRowMaterialsOfFinalProduct]);

    function setRowMaterialsForFinalProduct() {
        handleClose();
    }

    function loadRowMaterials() {
        axios.get(process.env.REACT_APP_API_URL + "inventories/finalproducts/false")
        .then(response => 
            {
                setRowMaterials(response.data.data);
            }
        )
        .catch(error => {
            if (error.response) {
                if(error.response.data.message != undefined){
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    function handleClose() {
        
        if (rowMaterialsOfFinalProduct.length <= 0) {
            inventoryDetails.isFinalProduct = false;
            toast.warn("This inventory will not be added as Final Product because you haven't selected any row material", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        
        setSelectedRowMaterial("");
        setQuantityUsed("");
        setApplyRowMaterials(false);
    }
    
    useMemo(() => {
        if (inventoryDetails.name != undefined && inventoryDetails.name.length > 0 && inventoryDetails.description != undefined && inventoryDetails.description.length > 0) {
            setSubmitDisabled(false);
        }
        else{
           setSubmitDisabled(true);
        }
    }, [inventoryDetails]);

    useMemo(() => {
        if (selectedRowMaterial > 0 && quantityUsed != '') {
            setAddRowMaterialtoArrayDisabled(false);
        }
        else{
            setAddRowMaterialtoArrayDisabled(true);
        }
    }, [selectedRowMaterial, quantityUsed]);

    function handleRowMaterialSelect(e) {

        setSelectedRowMaterial(e.target.value);
    }

    function addRowMaterialtoArray() {
        
        var filteredData = rowMaterials.filter(r => r.inventoryId == selectedRowMaterial);

        if(filteredData != undefined && filteredData.length > 0) {

            if(filteredData[0].quantity < quantityUsed) {
                toast.error("You don't have enough quantity available in stock for this row material", {
                    position: toast.POSITION.TOP_CENTER
                });

                return;
            }

            var duplicateData = tempRowMaterialsOfFinalProduct.filter(r => r.value == selectedRowMaterial);

            if(duplicateData != undefined && duplicateData.length > 0) {
                deleteItemFromAddedRowMaterials(duplicateData[0].value);
            }

            var selectedRow = { label: filteredData[0].name, value: filteredData[0].inventoryId, quantity: quantityUsed, purchasePrice: filteredData[0].purchasePrice };
            setTempRowMaterialsOfFinalProduct(tempRowMaterialsOfFinalProduct => [...tempRowMaterialsOfFinalProduct, selectedRow]);

            setSelectedRowMaterial("");
            setQuantityUsed("");
        }
    }

    function deleteItemFromAddedRowMaterials(id) {

        var filteredData = tempRowMaterialsOfFinalProduct.filter(r => r.value != id);

        if(filteredData.length > 0) {
            setTempRowMaterialsOfFinalProduct(filteredData);
        }
        else { 
            setTempRowMaterialsOfFinalProduct([]);
        }
    }

    function updateRowMaterials() {
        loadRowMaterials();
        //setApplyRowMaterials(false);
        setApplyRowMaterials(true);
    }

    function submitInventoryDetails() {
        setSubmitDisabled(true);
        
        var productRowMaterials = [];

        if(inventoryDetails.isFinalProduct && rowMaterialsOfFinalProduct.length > 0) {
            for (var i=0; i < rowMaterialsOfFinalProduct.length; i++) {
                productRowMaterials.push({"InventoryRowMaterialId": rowMaterialsOfFinalProduct[i].value, "RowMaterialQuantity": parseInt(rowMaterialsOfFinalProduct[i].quantity), InventoryProductId: null});
            } 
        }

        axios.post(process.env.REACT_APP_API_URL + "inventories/add", {
            Name: inventoryDetails.name,
            Description: inventoryDetails.description,
            IsFinalProduct: inventoryDetails.isFinalProduct,
            PurchasePrice: inventoryDetails.purchasePrice,
            PurchaseGST: inventoryDetails.purchaseGST.toString(),
            SellingPrice: inventoryDetails.sellingPrice.toString(),
            SellingGST: inventoryDetails.sellingGST.toString(),
            Quantity: parseInt(inventoryDetails.quantity),
            IsActive: inventoryDetails.isActive,
            InventoryRowMaterialId: productRowMaterials
          })
          .then((response) => {

            if(response != null && response.data != undefined && response.data.success == true) {
                setInventoryDetails({name: '', description: '', isFinalProduct: false, purchasePrice: '', purchaseGST: 0, sellingPrice: '',sellingGST: 0,quantity: '', isActive: true});
                setTempRowMaterialsOfFinalProduct([]);
                setRowMaterialsOfFinalProduct([]);
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            setSubmitDisabled(false);

          }).catch(error => { // your error handling goes here};
            setSubmitDisabled(false);
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            //console.log(error.config);
          });
    }

    return (
        <div className="flex flex-1  flex-col md:flex-row lg:flex-row mx-2">
            <ToastContainer></ToastContainer>
            <Modal dialogClassName="inventory-modal" show={applyRowMaterials} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Row Materials and quantity used to build this final product !!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1"
                                    for="grid-first-name">
                                Row Materials
                            </label>
                            <select value={selectedRowMaterial} onChange={e => handleRowMaterialSelect(e)} className="block appearance-none w-full bg-grey-200 border border-grey-200 text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey">
                                <option value="0">-- Select Row Material --</option>
                                {rowMaterials.map(item => (
                                    <option
                                    key={item.inventoryId}
                                    value={item.inventoryId}
                                    >
                                    {item.name + " (Avl Qty: " + item.quantity + ")"}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="w-full md:w-1/3 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1"
                                    for="grid-last-name">
                                Quantity
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                        type="number" value={quantityUsed} onChange={handleUsedQuantityChange} placeholder="Quantity Used" name="quantityUsed"></input>
                                        
                        </div>
                        <div>
                            <div className="w-full md:w-1/4 px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1"
                                        for="grid-last-name">
                                    &nbsp;
                                </label>
                                <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                                    type="button" onClick={addRowMaterialtoArray} disabled={addRowMaterialtoArrayDisabled}>
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                    
                    { tempRowMaterialsOfFinalProduct.length > 0 && tempRowMaterialsOfFinalProduct.map((rowMaterial, index) => (
                        <div className="text-gray-500">
                            {rowMaterial.label} (Qty Used: {rowMaterial.quantity})
                            <a title="Delete Item" className="cursor-pointer pl-2" onClick={() => deleteItemFromAddedRowMaterials(rowMaterial.value)}><FontAwesomeIcon icon={faTrashAlt} size="xs" /></a>
                        </div>
                    ))}

                    { tempRowMaterialsOfFinalProduct.length > 0 && <div className="text-gray-500 mt-6">
                        Approximate cost of final product : <FontAwesomeIcon icon={faRupeeSign} size="sm" />{tempRowMaterialsOfFinalProduct.reduce((total, currentValue) => total = parseInt(total) + (parseInt(currentValue.purchasePrice) * parseInt(currentValue.quantity)),0)} 
                    </div>}

                    
                </Modal.Body>
                <Modal.Footer>
                <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" onClick={setRowMaterialsForFinalProduct}>
                    Done
                </Button>
                <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" onClick={handleClose}>
                    Cancel
                </Button>
                </Modal.Footer>
            </Modal>
            <div className="mb-2 border-solid border-gray-300 rounded border shadow-sm w-full">
                <div className="bg-gray-200 px-2 py-3 border-solid border-gray-200 border-b">
                    <b>ADD NEW STOCK</b>
                </div>
                <div className="p-3">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Name
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="text" placeholder="Name" value={inventoryDetails.name} onChange={handleChange} name="name"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-4/4 px-3 mb-6 md:mb-0 relative">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Description
                            </label>
                            <textarea maxLength="500" className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                placeholder="Description" value={inventoryDetails.description} onChange={handleChange} name="description"></textarea>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Purchase Price (for 1 quantity)
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="number" placeholder="Purchase Price" value={inventoryDetails.purchasePrice} onChange={handleChange} name="purchasePrice"></input>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Purchase GST (%)
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="number" placeholder="Purchase GST" value={inventoryDetails.purchaseGST} onChange={handleChange} name="purchaseGST"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Selling Price (for 1 quantity)
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="number" placeholder="Purchase Price" value={inventoryDetails.sellingPrice} onChange={handleChange} name="sellingPrice"></input>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Selling GST (%)
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="number" placeholder="Purchase GST" value={inventoryDetails.sellingGST} onChange={handleChange} name="sellingGST"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 relative">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Available Quantity
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="number" placeholder="Available Quantity" value={inventoryDetails.quantity} onChange={handleChange} name="quantity"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                            <label class="md:w-2/3 block font-regular text-grey-dark">
                                <input class="mr-2 leading-tight" type="checkbox" checked={inventoryDetails.isActive} onChange={handleChange} name="isActive"></input>
                                <span class="text-sm">
                                    Active
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                            <label class="md:w-2/3 block font-regular text-grey-dark">
                                <input class="mr-2 leading-tight" type="checkbox" checked={inventoryDetails.isFinalProduct} onChange={handleChange} name="isFinalProduct"></input>
                                <span class="text-sm">
                                    Final Product
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="w-full flex flex-wrap -mx-3">
                        { rowMaterialsOfFinalProduct.length > 0 && rowMaterialsOfFinalProduct.map((rowMaterial, index) => (
                            <div className="w-full text-gray-500 text-sm px-3">
                                {rowMaterial.label} (Qty Used: {rowMaterial.quantity})
                            </div>

                        ))}
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        {rowMaterialsOfFinalProduct.length > 0 && 
                        <button onClick={updateRowMaterials} class="flex-shrink-0 border-transparent border-4 text-teal-500 hover:text-teal-700 text-sm py-1 px-2 rounded" type="button">
                            Update Row Materials
                        </button>}
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                                type="button" onClick={submitInventoryDetails} disabled={submitDisabled}>
                                Add
                            </button>
                            <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded ml-2"
                                    type="button" onClick={() => history.push("/inventories")}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default NewInventory;