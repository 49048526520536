import axios from "axios";
import React, { useState, useMemo } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator';
//import * as AppConstants from '../../components/common/constants.js';

function Login() {
    //const [errorMessage, setError] = useState("");
    //const [showErrorContainer, setErrorContainer] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(true);

    useMemo(() => {
        if (email != undefined && email.length > 0 && password != undefined && password.length > 0) {
            if(validator.isEmail(email)){
                setSubmitDisabled(false);
            }
            else{
                setSubmitDisabled(true);
            }
        }
        else{
           setSubmitDisabled(true);
        }
    }, [email,password]);

    function userLogin(){

        setSubmitDisabled(true);

        axios.post(process.env.REACT_APP_API_URL + "admin/login", {
            Email: email,
            Password: password
          })
          .then((response) => {

            if(response != null && response.data != undefined && response.data.success == true) {
                const userDetails = {
                    isLoggedIn: true,
                    email: response.data.data.email,
                    name:  response.data.data.name,
                    isActive: response.data.data.isActive,
                    adminId:  response.data.data.adminId,
                };
                window.sessionStorage.setItem('UserDetails', JSON.stringify(userDetails));
                window.location.reload();
            }
            setSubmitDisabled(false);
          }).catch(error => { // your error handling goes here};
            setSubmitDisabled(false);
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            //console.log(error.config);
          });
    }
    return (
        <div className="h-screen font-sans login bg-cover">
            <ToastContainer></ToastContainer>
            <div className="container mx-auto h-full flex flex-1 justify-center items-center">
                <div className="w-full max-w-lg">
                    <div className="leading-loose">
                        <div className="max-w-xl m-4 p-10 bg-white rounded shadow-xl">
                            <p className="text-gray-800 font-medium text-center text-lg font-bold">{process.env.REACT_APP_NAME} - ADMINISTRATION</p>
                            <div>
                                <label className="block text-sm text-gray-00" for="username">Email</label>
                                <input className="w-full px-5 py-1 text-gray-700 bg-gray-200 rounded" id="username" name="username" type="text" required="" onChange={(event) => setEmail(event.target.value)} placeholder="Email" aria-label="username" value={email}></input>
                            </div>
                            <div className="mt-2">
                                <label className="block text-sm text-gray-600" for="password">Password</label>
                                <input className="w-full px-5  py-1 text-gray-700 bg-gray-200 rounded" id="password" name="password" type="password" required="" onChange={(event) => setPassword(event.target.value)} placeholder="*******" aria-label="password"></input>
                            </div>
                            <div className="mt-4 items-center justify-between">
                                <button className="px-4 py-1 text-white font-light tracking-wider bg-gray-900 rounded" disabled={submitDisabled} onClick={userLogin}>Login</button>
                                <a className="inline-block right-0 align-baseline  font-bold text-sm text-500 hover:text-blue-800" href="#">
                                    Forgot Password?
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Login;