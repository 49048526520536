import { useParams } from 'react-router-dom';
import axios from "axios";
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import validator from 'validator';

function AdminDetails() {
    const params = useParams();
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    const [adminDetails, setAdminDetails] = useState({name: '', email: '', password: '', isActive: false});
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "admins/" + params.adminId)
        .then(response => 
            {
                if(response.data.data) {
                    setAdminDetails(response.data.data);
                }
                else
                {
                    history.push("/notfound");
                }
            }
        )
        .catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    },[]);

    const handleChange = e => {
        const { name, value, checked } = e.target;
        setAdminDetails(prevState => ({
            ...prevState,
            [name]: e.target.type === "checkbox" ? checked : value 
        }));
    };

    useMemo(() => {
        if (adminDetails.name != undefined && adminDetails.name.length > 0 && adminDetails.email != undefined && adminDetails.email.length > 0 && adminDetails.password != undefined && adminDetails.password.length > 0) {
            if(validator.isEmail(adminDetails.email)){
                setSubmitDisabled(false);
            }
            else{
                setSubmitDisabled(true);
            }
        }
        else{
           setSubmitDisabled(true);
        }
    }, [adminDetails]);

    function submitAdminDetails() {
        
        setSubmitDisabled(true);

        axios.post(process.env.REACT_APP_API_URL + "admin/update", {
            AdminId: adminDetails.adminId,
            Name: adminDetails.name,
            Email: adminDetails.email,
            Password: adminDetails.password,
            IsActive: adminDetails.isActive
          })
          .then((response) => {

            if(response != null && response.data != undefined && response.data.success == true) {
                setAdminDetails(response.data.data);
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            setSubmitDisabled(false);

          }).catch(error => { // your error handling goes here};
            setSubmitDisabled(false);
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            //console.log(error.config);
          });
    }

    return (
        <div className="flex flex-1  flex-col md:flex-row lg:flex-row mx-2">
            <ToastContainer></ToastContainer>
            <div className="mb-2 border-solid border-gray-300 rounded border shadow-sm w-full">
                <div className="bg-gray-200 px-2 py-3 border-solid border-gray-200 border-b">
                    <b>UPDATE ADMIN DETAILS</b>
                </div>
                <div className="p-3">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Name
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="text" placeholder="Name" value={adminDetails.name} onChange={handleChange} name="name"></input>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Email
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="text" placeholder="Email" value={adminDetails.email} onChange={handleChange} name="email"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 relative">
                            <label class="block uppercase tracking-wide text-grey-darker text-xs font-light mb-1">
                                Password
                            </label>
                            <input class="appearance-none block w-full bg-grey-200 text-grey-darker border border-grey-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-grey"
                                    type={showPassword ? "text" : "password"} placeholder="******************" value={adminDetails.password} onChange={handleChange} name="password"></input>
                            <FontAwesomeIcon icon={showPassword == false ? faEye : faEyeSlash } onClick={() => setShowPassword(prevState => !prevState)} className="password-eye" />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                            <label class="md:w-2/3 block font-regular text-grey-dark">
                                <input class="mr-2 leading-tight" type="checkbox" checked={adminDetails.isActive} onChange={handleChange} name="isActive"></input>
                                <span class="text-sm">
                                    Active
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                                type="button" onClick={submitAdminDetails} disabled={submitDisabled}>
                                Update
                            </button>
                            <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded ml-2"
                                    type="button" onClick={() => history.push("/admins")}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AdminDetails;