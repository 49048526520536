import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

function WebUtility() {

    const history = useHistory();
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [webUtilityDetails, setWebUtilityDetails] = useState({contactAddress: '', contactPhone: '', contactEmail: '', webPhone: ''});

    const handleChange = e => {
        const { name, value, checked } = e.target;

        setWebUtilityDetails(prevState => ({
            ...prevState,
            [name]: e.target.type === "checkbox" ? checked : value 
        }));
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "webutility")
        .then(response => 
            {
                if(response.data.data) {
                    setWebUtilityDetails(response.data.data);
                }
            }
        )
        .catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    },[]);

    function submitWebUtilityDetails() {
        setSubmitDisabled(true);

        axios.post(process.env.REACT_APP_API_URL + "webutility/update", {
            UtilityId: webUtilityDetails.utilityId,
            ContactPhone: webUtilityDetails.contactPhone,
            ContactEmail: webUtilityDetails.contactEmail,
            ContactAddress: webUtilityDetails.contactAddress,
            WebPhone: webUtilityDetails.webPhone
          })
          .then((response) => {

            if(response != null && response.data != undefined && response.data.success == true) {
                setWebUtilityDetails(response.data.data);
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            setSubmitDisabled(false);

          }).catch(error => { // your error handling goes here};
            setSubmitDisabled(false);
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            //console.log(error.config);
          });
    }

    return (
        <div className="flex flex-col">
            <ToastContainer></ToastContainer>
            <div className="mb-2 border-solid border-gray-300 rounded border shadow-sm w-full">
                <div className="bg-gray-200 px-2 py-3 border-solid border-gray-200 border-b">
                    <b>CONTACT FORM ADDRESS (Address will be displayed on contact us form)</b>
                </div>
                <div className="p-3">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 relative">
                        <textarea maxLength="500" className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                placeholder="Address will be displayed on contact us form" value={webUtilityDetails.contactAddress} onChange={handleChange} name="contactAddress"></textarea>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-200 px-2 py-3 border-solid border-gray-200 border-b">
                    <b>CONTACT FORM PHONE NUMBER (Phone number will be displayed on contact form)</b>
                </div>
                <div className="p-3">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 relative">
                        <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="number" placeholder="Phone number will be displayed on contact form" name="contactPhone" value={webUtilityDetails.contactPhone} onChange={handleChange}></input>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-200 px-2 py-3 border-solid border-gray-200 border-b">
                    <b>CONTACT FORM EMAIL (Email will be displayed on contact form)</b>
                </div>
                <div className="p-3">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 relative">
                        <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="text" placeholder="Email will be displayed on contact form" name="contactEmail" value={webUtilityDetails.contactEmail} onChange={handleChange}></input>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-200 px-2 py-3 border-solid border-gray-200 border-b">
                    <b>PHONE NUMBER (Phone number will be displayed on website header)</b>
                </div>
                <div className="p-3">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 relative">
                        <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="number" placeholder="Phone number will be displayed on website header" name="webPhone" value={webUtilityDetails.webPhone} onChange={handleChange}></input>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-2 p-3">
                    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                            type="button" onClick={submitWebUtilityDetails} disabled={submitDisabled}>
                            Update
                        </button>
                        <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded ml-2"
                                type="button" onClick={() => history.push("/")}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default WebUtility;