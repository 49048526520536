import AppConstants from '../../components/common/constants.js';
import axios from "axios";
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';

function Landing() {

    const [dashboardData, setDashboardData] = useState([]);

    useEffect(() => {
        loadDashboardDetails();
    },[]);

    function loadDashboardDetails() {
        axios.get(process.env.REACT_APP_API_URL + "dashboard")
        .then(response => 
            {
                setDashboardData(response.data.data);
            }
        )
        .catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    return (
        <div>
            <div className="flex flex-1  flex-col md:flex-row lg:flex-row mx-2">
                <ToastContainer></ToastContainer>
                <div className="flex flex-1 flex-col md:flex-row lg:flex-row mx-2 mt-5">
                    <div className="rounded overflow-hidden shadow bg-white mx-2 w-full">
                        <div className="px-6 py-2 border-b border-light-grey">
                            <div className="font-bold text-xl">Products (Website)</div>
                        </div>
                        <div className="table-responsive">
                            <table className="table text-grey-darkest">
                                <thead className="bg-grey-dark text-white text-normal">
                                <tr>
                                    <th scope="col" style={{width: "300px"}}>&nbsp;</th>
                                    <th scope="col">&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td scope="row">Total</td>
                                    <th scope="row">{dashboardData.totalProducts}</th>
                                </tr>
                                <tr>
                                    <td scope="row">Trending</td>
                                    <th scope="row">{dashboardData.trendingProducts}</th>
                                </tr>

                                <tr>
                                    <td scope="row">Recommanded</td>
                                    <th scope="row">{dashboardData.recommendedProducts}</th>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="flex flex-1 flex-col md:flex-row lg:flex-row mx-2 mt-5">
                    <div className="rounded overflow-hidden shadow bg-white mx-2 w-full">
                        <div className="px-6 py-2 border-b border-light-grey">
                            <div className="font-bold text-xl">Product Enquiry (Website)</div>
                        </div>
                        <div className="table-responsive">
                            <table className="table text-grey-darkest">
                                <thead className="bg-grey-dark text-white text-normal">
                                <tr>
                                    <th scope="col" style={{width: "300px"}}>&nbsp;</th>
                                    <th scope="col">&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td scope="row">Total</td>
                                    <th scope="row">{dashboardData.totalProductEnquiries}</th>
                                </tr>
                                <tr>
                                    <td scope="row">Today</td>
                                    <th scope="row">{dashboardData.todaysProductEnquiries}</th>
                                </tr>

                                <tr>
                                    <td scope="row">Pending to Reply</td>
                                    <th scope="row">{dashboardData.pendingProductEnquiries}</th>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="flex flex-1 flex-col md:flex-row lg:flex-row mx-2 mt-5">
                    <div className="rounded overflow-hidden shadow bg-white mx-2 w-full">
                        <div className="px-6 py-2 border-b border-light-grey">
                            <div className="font-bold text-xl">Contact Enquiry (Website)</div>
                        </div>
                        <div className="table-responsive">
                            <table className="table text-grey-darkest">
                                <thead className="bg-grey-dark text-white text-normal">
                                <tr>
                                    <th scope="col" style={{width: "300px"}}>&nbsp;</th>
                                    <th scope="col">&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td scope="row">Total</td>
                                    <th scope="row">{dashboardData.totalContacts}</th>
                                </tr>
                                <tr>
                                    <td scope="row">Today</td>
                                    <th scope="row">{dashboardData.todaysContacts}</th>
                                </tr>

                                <tr>
                                    <td scope="row">Pending to Reply</td>
                                    <th scope="row">{dashboardData.pendingContacts}</th>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="flex flex-1 flex-col md:flex-row lg:flex-row mx-2 mt-5">
                    <div className="rounded overflow-hidden shadow bg-white mx-2 w-full">
                        <div className="px-6 py-2 border-b border-light-grey">
                            <div className="font-bold text-xl">Dealers</div>
                        </div>
                        <div className="table-responsive">
                            <table className="table text-grey-darkest">
                                <thead className="bg-grey-dark text-white text-normal">
                                <tr>
                                    <th scope="col" style={{width: "300px"}}>&nbsp;</th>
                                    <th scope="col">&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td scope="row">Total</td>
                                    <th scope="row">{dashboardData.totalDealers}</th>
                                </tr>
                                <tr>
                                    <td scope="row">Registered</td>
                                    <th scope="row">{dashboardData.registeredDealers}</th>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-1  flex-col md:flex-row lg:flex-row mx-2">
                <div className="flex flex-1 flex-col md:flex-row lg:flex-row mx-2 mt-5">
                    <div className="rounded overflow-hidden shadow bg-white mx-2 w-full">
                        <div className="px-6 py-2 border-b border-light-grey">
                            <div className="font-bold text-xl">Invoice</div>
                        </div>
                        <div className="table-responsive">
                            <table className="table text-grey-darkest">
                                <thead className="bg-grey-dark text-white text-normal">
                                <tr>
                                    <th scope="col" style={{width: "300px"}}>&nbsp;</th>
                                    <th scope="col">&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td scope="row">Total</td>
                                    <th scope="row">{dashboardData.totalInvoices}</th>
                                </tr>
                                <tr>
                                    <td scope="row">Paid</td>
                                    <th scope="row">{dashboardData.totalInvoicePaid}</th>
                                </tr>
                                <tr>
                                    <td scope="row">Unpaid</td>
                                    <th scope="row">{dashboardData.totalInvoiceUnPaid}</th>
                                </tr>
                                <tr>
                                    <td scope="row">Today's Due</td>
                                    <th scope="row">{dashboardData.totalInvoiceDueToday}</th>
                                </tr>
                                <tr>
                                    <td scope="row">Due within 2 days</td>
                                    <th scope="row">{dashboardData.totalInvoiceDueWithin}</th>
                                </tr>
                                <tr>
                                    <td scope="row">Overdue</td>
                                    <th scope="row">{dashboardData.totalInvoiceOverdue}</th>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-1  flex-col md:flex-row lg:flex-row mx-2">
                <div className="flex flex-1 flex-col md:flex-row lg:flex-row mx-2 mt-5">
                    <div className="rounded overflow-hidden shadow bg-white mx-2 w-full">
                        <div className="px-6 py-2 border-b border-light-grey">
                            <div className="font-bold text-xl">Stock Management</div>
                        </div>
                        <div className="table-responsive">
                            <table className="table text-grey-darkest">
                                <thead className="bg-grey-dark text-white text-normal">
                                <tr>
                                    <th scope="col" style={{width: "300px"}}>&nbsp;</th>
                                    <th scope="col">&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td scope="row">Total</td>
                                    <th scope="row">{dashboardData.totalInventories}</th>
                                </tr>
                                <tr>
                                    <td scope="row">Row Material</td>
                                    <th scope="row">{dashboardData.totalInventoryRow}</th>
                                </tr>
                                <tr>
                                    <td scope="row">Final Product</td>
                                    <th scope="row">{dashboardData.totalInventoryFinal}</th>
                                </tr>
                                <tr>
                                    <td scope="row">Quantity Alert (below 2)</td>
                                    <th scope="row">{dashboardData.totalInventoryLowAlert}</th>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}
export default Landing;