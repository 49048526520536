import axios from "axios";
import React, { useState, useEffect, useMemo } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faTrashAlt, faEye, faRupeeSign } from '@fortawesome/free-solid-svg-icons'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, dateFilter  } from 'react-bootstrap-table2-filter';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Moment from 'react-moment';
import moment from 'moment';

function AllInvoices() {
    const [invoiceData, setInvoiceData] = useState([]);
    const [invoiceDataForGrid, setInvoiceDataForGrid] = useState([]);
    const [invoiceId, setInvoiceId] = useState(0);
    const [deleteDisabled, setDeleteDisabled] = useState(false);
    const [partialPaymentDisabled, setPartialPaymentDisabled] = useState(false);
    const [partialPaymentId, setPartialPaymentId] = useState(0);
    const [partialPayment, setPartialPayment] = useState(0);
    const handleClose = () => setInvoiceId(0);
    const handlePartialPaymentClose = () => setPartialPaymentId(0);
    const [invoiceFilterBy] = React.useState([
        { label: "All", value: "All" },
        { label: "Paid", value: "Paid" },
        { label: "Unpaid", value: "Unpaid" },
        { label: "Today's Due", value: "TodayDue" },
        { label: "Due within 2 days", value: "DueWithin" },
        { label: "Overdue", value: "Overdue" }
    ]);
    const [selectedInvoiceFilterBy, setSelectedInvoiceFilterBy] = useState("All");

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: true, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: true // Hide the pagination list when only one page
    };

    const columns = [{
        dataField: 'invoiceId',
        text: 'Invoice Id',
        hidden: true
      }, {
        dataField: 'invoiceNumber',
        text: 'Invoice No.',
        sort: true,
        filter: textFilter(),
        formatter: invoiceNumberFormatter,
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        }
      }, {
        dataField: 'companyName',
        text: 'Company',
        sort: true,
        filter: textFilter(),
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        }
      }, {
        dataField: 'dealerName',
        text: 'Dealer',
        sort: true,
        filter: textFilter(),
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        }
      }, {
        dataField: 'netAmount',
        text: 'Net Amount',
        sort: true,
        filter: textFilter(),
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        },
        align: 'right'
      }, {
        dataField: 'partialPayment',
        text: 'Partial Paid',
        sort: true,
        filter: textFilter(),
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        },
        align: 'right'
      }, {
        dataField: 'isPaid',
        text: 'Paid',
        sort: true,
        align: 'center',
        formatter: paidColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        }
      }, {
        dataField: 'paymentDueDate',
        text: 'Due Date',
        sort: true,
        formatter: dueDateColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        }
      }, {
        dataField: 'paidDate',
        text: 'Paid Date',
        sort: true,
        formatter: paidDateColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        }
      }, {
        dataField: 'createdDate',
        text: 'Created Date',
        sort: true,
        formatter: dateColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        }
      }, {
        dataField: '',
        text: 'Action',
        align: 'center',
        formatter: editColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { textAlign: 'center' };
        }
      }];

    useEffect(() => {
        loadInvoiceRecords();
    },[]);

    useMemo(() => {
        setInvoiceDataForGrid(invoiceData);
        filterInvoiceRecords(selectedInvoiceFilterBy);
    }, [invoiceData]);

    function handleInvoiceFilterBySelect(e) {
        setSelectedInvoiceFilterBy(e.target.value);

        filterInvoiceRecords(e.target.value);
    }

    function filterInvoiceRecords(type) {
        var filteredData = [];
        const today = moment();
        const within = moment().add(2, 'day');
        console.log(within.format('MM/DD/YYYY'));
        if(type == 'Paid') {
            filteredData = invoiceData.filter(i => i.isPaid == true);
        }
        else if(type == 'Unpaid') {
            filteredData = invoiceData.filter(i => i.isPaid == false);
        }
        else if(type == 'TodayDue') {
            filteredData = invoiceData.filter(i => today.isSame(i.paymentDueDate, 'day') && i.isPaid == false);
        }
        else if(type == 'DueWithin') {
            filteredData = invoiceData.filter(i => moment(i.paymentDueDate).format('MM/DD/YYYY') <= within.format('MM/DD/YYYY') && moment(i.paymentDueDate).format('MM/DD/YYYY') > today.format('MM/DD/YYYY') && i.isPaid == false);
        }
        else if(type == 'Overdue') {
            filteredData = invoiceData.filter(i => moment(i.paymentDueDate).format('MM/DD/YYYY') < today.format('MM/DD/YYYY') && i.isPaid == false);
        }
        else {
            filteredData = invoiceData;
        }
        
        setInvoiceDataForGrid(filteredData);
    }

    function updatePartialPaymentOpen(id)
    {
        setPartialPayment(0);
        setPartialPaymentId(id);
    }

    function dateColumnFormatter(cell, row) {
        return <Moment format="DD/MM/YYYY">{row.createdDate}</Moment>;
    }

    function paidDateColumnFormatter(cell, row) {
        if(row.paidDate != null) {
            return <Moment format="DD/MM/YYYY">{row.paidDate}</Moment>;
        }
        else {
            return "";
        }
        
    }

    function dueDateColumnFormatter(cell, row) {
        if(row.paymentDueDate != null) {
            return <Moment format="DD/MM/YYYY">{row.paymentDueDate}</Moment>;
        }
        else {
            return "";
        }
        
    }

    function paidColumnFormatter(cell, row) {
        
        if (row.isPaid) {
            return <a title="Paid" className="cursor-pointer pl-2" onClick={() => updateInvoicePaid(false, row.invoiceId)}><FontAwesomeIcon icon={faCheck} /></a>;
        }
        else{
            return <a title="Pending" className="cursor-pointer pl-2" onClick={() => updateInvoicePaid(true, row.invoiceId)}><FontAwesomeIcon icon={faTimes} /></a>;
        }
    }

    function invoiceNumberFormatter(cell, row) {
        return (
            <div>
                {row.companyPrefix + row.invoiceNumber}
            </div>
        );
    }

    function editColumnFormatter(cell, row) {
        return (
            <div>
                <a title="Update Partial Payment" className="cursor-pointer pl-2" onClick={() => updatePartialPaymentOpen(row.invoiceId)}><FontAwesomeIcon icon={faRupeeSign} /></a>
                <a title="Delete Invoice" className="cursor-pointer pl-2" onClick={() => deleteInvoiceConfirmation(row.invoiceId)}><FontAwesomeIcon icon={faTrashAlt} /></a>
                <a title="View Invoice" className="cursor-pointer pl-2" href={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "invoices/" + row.invoiceFile } target="_blank"><FontAwesomeIcon icon={faEye} /></a>
            </div>
        );
    }

    function deleteInvoiceConfirmation(id) {
        setInvoiceId(id);
    }

    function deleteInvoiceRecord() {
        setDeleteDisabled(true);

        axios.post(process.env.REACT_APP_API_URL + "invoices/delete/" + invoiceId, null)
          .then((response) => {

            if(response != null && response.data.success == true) {
                setInvoiceId(0);
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                loadInvoiceRecords();
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            setDeleteDisabled(false);

          }).catch(error => { // your error handling goes here};
            setDeleteDisabled(false);
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
          });
    }
    
    function loadInvoiceRecords() {
        axios.get(process.env.REACT_APP_API_URL + "invoices")
        .then(response => 
            {
                setInvoiceData(response.data.data);
            }
        )
        .catch(error => {
            if (error.response) {
                if(error.response.data.message != undefined){
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    function updateInvoicePaid(isPaid, invoiceId) {
        axios.post(process.env.REACT_APP_API_URL + "invoices/paid/" + invoiceId + "/" + isPaid, null)
          .then((response) => {

            if(response != null && response.data != undefined && response.data.success == true) {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                loadInvoiceRecords();
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

          }).catch(error => { // your error handling goes here};

            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            //console.log(error.config);
          });
    }

    function handlePartialPaymentChange(e) {
        setPartialPayment(e.target.value);
    }

    useMemo(() => {
        
        if (partialPayment != '') {
            setPartialPaymentDisabled(false);
        }
        else{
            setPartialPaymentDisabled(true);
        }
    }, [partialPayment]);

    function updatePartialPayment() {
        setPartialPaymentDisabled(true);

        axios.post(process.env.REACT_APP_API_URL + "invoices/partialpaid/" + partialPaymentId + "/" + partialPayment, null)
          .then((response) => {

            if(response != null && response.data.success == true) {
                setPartialPayment(0);
                setPartialPaymentId(0);
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                loadInvoiceRecords();
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            setPartialPaymentDisabled(true);

          }).catch(error => { // your error handling goes here};
            setPartialPaymentDisabled(true);
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
          });
    }

    return (
        <div>
            <ToastContainer></ToastContainer>
            <Modal show={invoiceId > 0} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Confirmation !!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this record?
                </Modal.Body>
                <Modal.Footer>
                <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" disabled={deleteDisabled} onClick={deleteInvoiceRecord}>
                    Yes
                </Button>
                <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" onClick={handleClose}>
                    No
                </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={partialPaymentId > 0} onHide={handlePartialPaymentClose}>
                <Modal.Header closeButton>
                <Modal.Title>Update Partial Payment  !!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1"
                                    for="grid-first-name">
                                Payment
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                        type="number" placeholder="Partial Payment" value={partialPayment} onChange={handlePartialPaymentChange} name="partialPayment"></input>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" disabled={partialPaymentDisabled} onClick={updatePartialPayment}>
                    Update
                </Button>
                <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" onClick={handlePartialPaymentClose}>
                    Cancel
                </Button>
                </Modal.Footer>
            </Modal>
            <div className="bg-gray-300 px-2 py-3 border-solid border-gray-400 border-b">
                <b>INVOICES</b>
            </div>
            <div className="p-3 w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-grey-darker text-xs font-light mb-1"
                        for="grid-state">
                    Filter By
                </label>
                <div className="relative">
                    <select value={selectedInvoiceFilterBy} onChange={e => handleInvoiceFilterBySelect(e)} className="block appearance-none w-full bg-grey-200 border border-grey-200 text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey">
                        {invoiceFilterBy.map(item => (
                            <option
                            key={item.value}
                            value={item.value}
                            >
                            {item.label}
                            </option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-grey-darker">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="p-3">
                <BootstrapTable keyField='id' data={invoiceDataForGrid} columns={columns} pagination={ paginationFactory(options) } filter={ filterFactory() } filterPosition="top"  />
            </div>
        </div>
    )
}

export default AllInvoices;