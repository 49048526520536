import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, dateFilter  } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../assets/styles/pagination.css';
import axios from "axios";
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';

function Contacts() {

    const [contactsData, setContactsData] = useState([]);
    const [contactMessage, setContactMessage] = useState('');
    const handleClose = () => setContactMessage('');

    useEffect(() => {
        loadContactsRecords();
    },[]);

    function loadContactsRecords() {
        axios.get(process.env.REACT_APP_API_URL + "contacts")
        .then(response => 
            {
                setContactsData(response.data.data);
            }
        )
        .catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    function updateContacted(isContacted, contactId) {
        axios.post(process.env.REACT_APP_API_URL + "contacts/updatecontacted", {
            ContactId: contactId,
            IsContacted: isContacted
          })
          .then((response) => {

            if(response != null && response.data != undefined && response.data.success == true) {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                loadContactsRecords();
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

          }).catch(error => { // your error handling goes here};

            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            //console.log(error.config);
          });
    }

    function messageColumnFormatter(cell, row) {
        return <a title="Click to read message" className="cursor-pointer" onClick={() => showContactMessage(row.message)}><FontAwesomeIcon icon={faEnvelope} /></a>;
    }

    function contactedColumnFormatter(cell, row) {
        if (row.isContacted) {
            return <a title="Contacted" className="cursor-pointer" onClick={() => updateContacted(false, row.contactId)}><FontAwesomeIcon icon={faCheck} /></a>;
        }
        else{
            return <a title="Not Contacted" className="cursor-pointer" onClick={() => updateContacted(true, row.contactId)}><FontAwesomeIcon icon={faTimes} /></a>;
        }
    }

    function dateColumnFormatter(cell, row) {
        return <Moment format="DD/MM/YYYY">{row.createdDate}</Moment>;
    }

    function showContactMessage(message) {
        setContactMessage(message);
    }

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: true, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: true // Hide the pagination list when only one page
      };

      const columns = [{
        dataField: 'contactId',
        text: 'Contact Id',
        hidden: true
      }, {
        dataField: 'name',
        text: 'Name',
        sort: true,
        filter: textFilter()
      }, {
        dataField: 'email',
        text: 'Email',
        sort: true,
        filter: textFilter()
      }, {
        dataField: 'phone',
        text: 'Phone',
        sort: true,
        filter: textFilter()
      }, {
        dataField: 'createdDate',
        text: 'Date',
        sort: true,
        formatter: dateColumnFormatter,
      }, {
        dataField: 'message',
        text: 'Message',
        formatter: messageColumnFormatter,
        align: 'center',
        headerStyle: (colum, colIndex) => {
            return { width: '100px', textAlign: 'center' };
        }
      }, {
        dataField: '',
        text: 'Contacted',
        align: 'center',
        formatter: contactedColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { width: '100px', textAlign: 'center' };
        }
      }];

    return (
        <div>
            <ToastContainer></ToastContainer>
            <Modal show={contactMessage.length > 0} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Message from contact enquiry !!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {contactMessage}
                </Modal.Body>
                <Modal.Footer>
                <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" onClick={handleClose}>
                    Ok
                </Button>
                </Modal.Footer>
            </Modal>

            <div class="bg-gray-300 px-2 py-3 border-solid border-gray-400 border-b">
                <b>CONTACT ENQUIRIES</b>
            </div>
            <div class="p-3">
                <BootstrapTable keyField='id' data={contactsData} columns={columns} pagination={ paginationFactory(options) } filter={ filterFactory() } filterPosition="top"  />
            </div>
        </div>
    );
}
export default Contacts;