import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../assets/styles/pagination.css';
import axios from "axios";
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes, faCheck, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

function AllAdmins() {
    const [adminData, setAdminData] = useState([]);
    const [adminId, setAdminId] = useState(0);
    const [deleteDisabled, setDeleteDisabled] = useState(false);

    const handleClose = () => setAdminId(0);

    useEffect(() => {
        loadAdminRecords();
    },[]);

    function loadAdminRecords() {
        axios.get(process.env.REACT_APP_API_URL + "admins")
        .then(response => 
            {
                setAdminData(response.data.data);
            }
        )
        .catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    function deleteAdminConfirmation(id){
        setAdminId(id);
    }

    function deleteAdminRecord () {

        setDeleteDisabled(true);

        axios.post(process.env.REACT_APP_API_URL + "admin/delete/" + adminId, null)
          .then((response) => {

            if(response != null && response.data.success == true) {
                setAdminId(0);
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                loadAdminRecords();
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            setDeleteDisabled(false);

          }).catch(error => { // your error handling goes here};
            setDeleteDisabled(false);
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
          });
    }
    
    //https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Column%20Filter&selectedStory=Filter%20Position&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: true, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: true // Hide the pagination list when only one page
      };

    function activeColumnFormatter(cell, row) {
        if (row.isActive) {
            return <FontAwesomeIcon title="Active" icon={faCheck} />;
        }
        else{
            return <FontAwesomeIcon title="InActive" icon={faTimes} />;
        }
    }

    function editColumnFormatter(cell, row) {
        return (
            <div>
                <Link title="Update Admin Details" to={'/admindetails/'+ row.adminId }><FontAwesomeIcon icon={faEdit} /></Link>
                <a title="Delete Admin" className="cursor-pointer pl-2" onClick={() => deleteAdminConfirmation(row.adminId)}><FontAwesomeIcon icon={faTrashAlt} /></a>
            </div>
        );
    }

    const columns = [{
        dataField: 'adminId',
        text: 'Admin Id',
        hidden: true
      }, {
        dataField: 'name',
        text: 'Name',
        sort: true,
        filter: textFilter()
      }, {
        dataField: 'email',
        text: 'Email',
        sort: true,
        filter: textFilter()
      }, {
        dataField: 'isActive',
        text: 'Active',
        sort: true,
        align: 'center',
        formatter: activeColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { width: '100px', textAlign: 'center' };
        }
      }, {
        dataField: '',
        text: 'Action',
        align: 'center',
        formatter: editColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { width: '100px', textAlign: 'center' };
        }
      }];

    return (
        <div class="mb-2 border-solid border-grey-light rounded border shadow-sm w-full">
            <ToastContainer></ToastContainer>
            <Modal show={adminId > 0} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Confirmation !!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this record?
                </Modal.Body>
                <Modal.Footer>
                <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" disabled={deleteDisabled} onClick={deleteAdminRecord}>
                    Yes
                </Button>
                <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" onClick={handleClose}>
                    No
                </Button>
                </Modal.Footer>
            </Modal>


            <div class="bg-gray-300 px-2 py-3 border-solid border-gray-400 border-b">
                <b>ADMIN USERS</b>
            </div>
            <div class="p-3">
                <BootstrapTable keyField='id' data={adminData} columns={columns} pagination={ paginationFactory(options) } filter={ filterFactory() } filterPosition="top"  />
            </div>
        </div>
    );
}
export default AllAdmins;