import React, { useState, useEffect, useMemo } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";  
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashAlt } from '@fortawesome/free-solid-svg-icons'

function NewInvoice() {

    const history = useHistory();
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [companies, setCompanies] = React.useState([]);
    const [dealers, setDealers] = React.useState([]);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [selectedDueDate, setSelectedDueDate] = useState("");
    const [selectedDealer, setSelectedDealer] = useState("");
    const [selectedSaleType, setSelectedSaleType] = React.useState([]);
    const [selectedPaymentType, setSelectedPaymentType] = React.useState([]);
    const [applyProductsToInvoice, setApplyProductsToInvoice] = useState(false);
    const [products, setProducts] = React.useState([]);
    const [selectedProduct, setSelectedProduct] = useState("");
    const [sellingGST, setSellingGST] = useState(0);
    const [sellingPrice, setSellingPrice] = useState(0);
    const [productToInvoice, setProductToInvoice] = useState({quantity: '', remarks: '', sellingGST: 0, sellingPrice: ''});
    const [tempSelectedProductsForInvoice, setTempSelectedProductsForInvoice] = useState([]);
    const [addProductToInvoiceDisabled, setAddProductToInvoiceDisabled] = useState(true);
    const [invoiceSummary, setInvoiceSummary] = useState({grossAmount: '', netAmount: '', cgst: '', sgst: '', igst: ''});
    const [showPDFInfo, setShowPDFInfo] = React.useState(false);
    const [pdfName, setPdfName] = React.useState([]);
    const [isNewCustomer, setIsNewCustomer] = useState(false);
    const [showNewCustomer, setShowNewCustomer] = useState(false);
    const [selectedRetailCustomer, setSelectedRetailCustomer] = useState("");
    const [retailCustomers, setRetailCustomers] = React.useState([]);
    const [retailCustomerDetails, setRetailCustomerDetails] = React.useState({name:'', phone: '', address: '', city: '', state: '', pincode: ''});
    const [newCustomerSubmitDisabled, setNewCustomerSubmitDisabled] = useState(true);
    
    useEffect(() => {
        loadDealers();
        loadCompanies();
    }, []);

    const handleProductToInvoiceChange = e => {
        const { name, value, checked } = e.target;

        setProductToInvoice(prevState => ({
            ...prevState,
            [name]: e.target.type === "checkbox" ? checked : value 
        }));

        if (name != undefined && name == 'sellingGST') {
            setSellingGST(value);
        }
        if (name != undefined && name == 'sellingPrice') {
            setSellingPrice(value);
        }
    };

    function loadDealers() {
        axios.get(process.env.REACT_APP_API_URL + "dealers")
        .then(response => 
            {
                setDealers(response.data.data);
            }
        )
        .catch(error => {
            if (error.response) {
                if(error.response.data.message != undefined){
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    function loadRetailCustomers() {
        axios.get(process.env.REACT_APP_API_URL + "dealers/retailcustomers")
        .then(response => 
            {
                setRetailCustomers(response.data.data);
            }
        )
        .catch(error => {
            if (error.response) {
                if(error.response.data.message != undefined){
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    function loadCompanies() {
        axios.get(process.env.REACT_APP_API_URL + "companies")
        .then(response => 
            {
                setCompanies(response.data.data);
            }
        )
        .catch(error => {
            if (error.response) {
                if(error.response.data.message != undefined){
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    function handleNewCustomerChange(e) {
        setIsNewCustomer(e.target.checked);
        if(e.target.checked == true) {
            setSelectedRetailCustomer('');
            setRetailCustomerDetails({name:'', phone: '', address: '', city: '', state: '', pincode: ''});
            setShowNewCustomer(e.target.value);
            loadRetailCustomers();
        }
    }

    function handleDealerSelect(e) {
        setSelectedDealer(e.target.value);
    }

    function handleRetailCustomerSelect(e) {
        setSelectedRetailCustomer(e.target.value);

        
    }

    useMemo(() => {
        
        if(selectedRetailCustomer != null && selectedRetailCustomer != '0' && selectedRetailCustomer != '') {
            var filteredCustomers = retailCustomers.filter(r => r.dealerId == selectedRetailCustomer);
            
            if(filteredCustomers != null && filteredCustomers.length > 0){
                setRetailCustomerDetails({name: filteredCustomers[0].name, phone: filteredCustomers[0].phone, address: filteredCustomers[0].address, city: filteredCustomers[0].city, state: filteredCustomers[0].state, pincode: filteredCustomers[0].pincode});
            }
        }
        else {
            setRetailCustomerDetails({name:'', phone: '', address: '', city: '', state: '', pincode: ''});
        }
    }, [selectedRetailCustomer]);

    function handleRetailCustomerDetailChange(e) {
        const { name, value, checked } = e.target;

        setRetailCustomerDetails(prevState => ({
            ...prevState,
            [name]: e.target.type === "checkbox" ? checked : value 
        }));
    }

    function handleCompanySelect(e) {
        setSelectedCompany(e.target.value);
    }

    function handleProductSelect(e) {

        if (e.target != undefined && e.target.value != '0') {}

        var inventoryId = parseInt(e.target.value);
        var filteredProduct = products.filter(p => p.inventoryId == inventoryId);

        if (filteredProduct != null && filteredProduct.length > 0) {
            setSellingGST(filteredProduct[0].sellingGST);
            setSellingPrice(filteredProduct[0].sellingPrice);
        }

        setSelectedProduct(e.target.value);
    }

    function handleSaleTypeSelect(e) {
        setSelectedSaleType(e.target.value);
    }

    function handlePaymentTypeSelect(e) {
        setSelectedPaymentType(e.target.value);
    }

    function handleClose() {
        
        setApplyProductsToInvoice(false);
    }

    function handleNewCustomerClose() {
        setShowNewCustomer(false);
        setIsNewCustomer(false);
    }

    function handlePDFClose() {
        
        setShowPDFInfo(false);
    }

    function clickProductsToInvoice() {

        loadAllProducts();

        setApplyProductsToInvoice(true);
    }

    function loadAllProducts() {
        axios.get(process.env.REACT_APP_API_URL + "inventories/available")
        .then(response => 
            {
                setProducts(response.data.data);
            }
        )
        .catch(error => {
            if (error.response) {
                if(error.response.data.message != undefined){
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    useMemo(() => {
        if (selectedProduct > 0 && sellingGST != '' && productToInvoice.quantity != '' && sellingPrice != '') {
            setAddProductToInvoiceDisabled(false);
        }
        else{
            setAddProductToInvoiceDisabled(true);
        }
    }, [selectedProduct, sellingGST, productToInvoice, sellingPrice]);

    useMemo(() => {
        if (retailCustomerDetails.name != '' && retailCustomerDetails.phone != '') {
            setNewCustomerSubmitDisabled(false);
        }
        else{
            setNewCustomerSubmitDisabled(true);
        }
    }, [retailCustomerDetails]);

    useMemo(() => {
        if (selectedCompany != '' && selectedCompany != '0' && selectedDealer != '' && selectedDealer != '0' && selectedSaleType != '' && selectedSaleType != '0' && tempSelectedProductsForInvoice.length > 0) {
            setSubmitDisabled(false);
        }
        else{
            setSubmitDisabled(true);
        }
    }, [tempSelectedProductsForInvoice, selectedCompany, selectedDealer, selectedSaleType, isNewCustomer]);

    function addProductToInvoice() {
        
        var gstValue = 0, price = 0, amount = 0, cgst = 0, sgst = 0, igst = 0, grossAmount = 0, name = '';

        var filteredProduct = products.filter(p => p.inventoryId == parseInt(selectedProduct));
        if (filteredProduct != null && filteredProduct.length > 0) {

            if(parseInt(filteredProduct[0].quantity) < parseInt(productToInvoice.quantity)) {
                toast.error("You don't have enough quantity available in stock", {
                    position: toast.POSITION.TOP_CENTER
                });

                return;
            }

            name =  filteredProduct[0].serialNumber != null && filteredProduct[0].serialNumber.length > 0 ? filteredProduct[0].name + " (Sr. No. - " + filteredProduct[0].serialNumber + ")" : filteredProduct[0].name;
            price =  parseInt(sellingPrice);
            amount = price * (parseInt(productToInvoice.quantity));
            gstValue = ((sellingGST * price) / 100) * parseInt(productToInvoice.quantity);

            

            var addedProductInformation = {inventoryId: filteredProduct[0].inventoryId, name: name, remarks: productToInvoice.remarks, quantity: parseInt(productToInvoice.quantity), sellingGST: parseInt(sellingGST), price: price, gstValue: gstValue, amount: amount};
            setTempSelectedProductsForInvoice(tempSelectedProductsForInvoice => [...tempSelectedProductsForInvoice, addedProductInformation]);
            setProductToInvoice({quantity: '', remarks: '', sellingGST: 0});
            setSellingGST(0);
            setSellingPrice('');
            setSelectedProduct('');
        }

        
    }

    useEffect(() => {
        
        var totalGST = tempSelectedProductsForInvoice.reduce((total, currentValue) => total = parseInt(total) + (parseInt(currentValue.gstValue)),0);
        var grossAmount = tempSelectedProductsForInvoice.reduce((total, currentValue) => total = parseInt(total) + (parseInt(currentValue.amount)),0);
        setInvoiceSummary(prevState => ({
            ...prevState,
            ['grossAmount']: grossAmount,
            ['sgst']: parseInt(totalGST) / 2,
            ['cgst']: parseInt(totalGST) / 2,
            ['igst']: parseInt(totalGST),
            ['netAmount']: grossAmount + totalGST
        }));

        console.log(tempSelectedProductsForInvoice);
    }, [tempSelectedProductsForInvoice]);

    function deleteProductFromInvoiceList(id) {
        var filteredData = tempSelectedProductsForInvoice.filter(r => r.inventoryId != id);

        if(filteredData.length > 0) {
            setTempSelectedProductsForInvoice(filteredData);
        }
        else { 
            setTempSelectedProductsForInvoice([]);
        }
    }

    function generateInvoice() {
        setSubmitDisabled(true);
        
        var invoiceProductsRequest = [];

        if(tempSelectedProductsForInvoice.length > 0) {
            for (var i=0; i < tempSelectedProductsForInvoice.length; i++) {
                invoiceProductsRequest.push({InventoryId: tempSelectedProductsForInvoice[i].inventoryId, Name: tempSelectedProductsForInvoice[i].name, Quantity: parseInt(tempSelectedProductsForInvoice[i].quantity), Remarks: tempSelectedProductsForInvoice[i].remarks, GST: tempSelectedProductsForInvoice[i].sellingGST.toString(), GSTValue: tempSelectedProductsForInvoice[i].gstValue.toString(), Price: tempSelectedProductsForInvoice[i].price.toString(), Amount: tempSelectedProductsForInvoice[i].amount.toString()});
            } 
        }

        axios.post(process.env.REACT_APP_API_URL + "invoices/add", {
            CompanyId: parseInt(selectedCompany),
            DealerId: parseInt(selectedDealer),
            PaymentDueDate: selectedDueDate.length <= 0 ? null : selectedDueDate,
            SaleType: selectedSaleType,
            PaymentType: selectedPaymentType,
            GrossAmount: invoiceSummary.grossAmount.toString(),
            CGST: invoiceSummary.cgst.toString(),
            SGST: invoiceSummary.sgst.toString(),
            IGST: invoiceSummary.igst.toString(),
            NetAmount: invoiceSummary.netAmount.toString(),
            IsPaid: false,
            InvoiceProducts: invoiceProductsRequest
          })
          .then((response) => {

            if(response != null && response.data != undefined && response.data.success == true) {
                setInvoiceSummary({grossAmount: '', cgst: '', sgst: '', igst: '', netAmount: '' });
                setSelectedRetailCustomer('');
                setIsNewCustomer(false);
                setRetailCustomerDetails({name:'', phone: '', address: '', city: '', state: '', pincode: ''});
                setTempSelectedProductsForInvoice([]);
                setSelectedCompany('');
                setSelectedDealer('');
                setSelectedDueDate('');
                setSelectedSaleType('');
                setSelectedPaymentType('');
                
                if (response.data.data != undefined && response.data.data.invoiceFile != null) {
                    setPdfName(response.data.data.invoiceFile);
                    setShowPDFInfo(true);
                }
                else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            setSubmitDisabled(false);

          }).catch(error => { // your error handling goes here};
            setSubmitDisabled(false);
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            //console.log(error.config);
          });
    }

    function addNewRetailCustomer() {
        setNewCustomerSubmitDisabled(true);
        if(selectedRetailCustomer == '0' || selectedRetailCustomer == '') {
            axios.post(process.env.REACT_APP_API_URL + "dealers/add", {
                Name: retailCustomerDetails.name,
                Address: retailCustomerDetails.address,
                City: retailCustomerDetails.city,
                State: retailCustomerDetails.state,
                Phone: retailCustomerDetails.phone,
                Pincode: retailCustomerDetails.pincode,
                IsActive: true,
                IsRetail: true,
                showOnContact: false
              })
              .then((response) => {
    
                if(response != null && response.data != undefined && response.data.success == true) {
                    setSelectedDealer(response.data.data.dealerId);
                    setShowNewCustomer(false);
                }
                else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
    
                setNewCustomerSubmitDisabled(false);
    
              }).catch(error => { // your error handling goes here};
                setNewCustomerSubmitDisabled(false);
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                } else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                //console.log(error.config);
              });
        }
        else {
            setSelectedDealer(selectedRetailCustomer);
            setShowNewCustomer(false);
        }
    }

    return (
        <div className="flex flex-1  flex-col md:flex-row lg:flex-row mx-2">
            <ToastContainer></ToastContainer>
            <Modal dialogClassName="inventory-modal" show={showNewCustomer} onHide={handleNewCustomerClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select retail customer or add new one !!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1"
                                    for="grid-first-name">
                                Retail Customer (don't select any customer to add new one)
                            </label>
                            <select value={selectedRetailCustomer} onChange={e => handleRetailCustomerSelect(e)} className="block appearance-none w-full bg-grey-200 border border-grey-200 text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey">
                                <option value="0">-- Select Retail Customer --</option>
                                {retailCustomers.map(item => (
                                    <option
                                    key={item.dealerId}
                                    value={item.dealerId}
                                    >
                                    {item.name + " (" + item.city +")"}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1"
                                    for="grid-first-name">
                                Name
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                        type="text" value={retailCustomerDetails.name} onChange={handleRetailCustomerDetailChange} placeholder="Name" name="name"></input>
                        </div>
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1"
                                    for="grid-first-name">
                                Phone
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                        type="text" value={retailCustomerDetails.phone} onChange={handleRetailCustomerDetailChange} placeholder="Phone" name="phone"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-4/4 px-3 mb-6 md:mb-0 relative">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Address
                            </label>
                            <textarea maxLength="500" className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                placeholder="Address" value={retailCustomerDetails.address} onChange={handleRetailCustomerDetailChange} name="address"></textarea>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1"
                                    for="grid-first-name">
                                City
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                        type="text" value={retailCustomerDetails.city} onChange={handleRetailCustomerDetailChange} placeholder="City" name="city"></input>
                        </div>
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1"
                                    for="grid-first-name">
                                State
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                        type="text" value={retailCustomerDetails.state} onChange={handleRetailCustomerDetailChange} placeholder="State" name="state"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1"
                                    for="grid-first-name">
                                Pin Code
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                        type="text" value={retailCustomerDetails.pincode} onChange={handleRetailCustomerDetailChange} placeholder="Pin Code" name="pincode"></input>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" disabled={newCustomerSubmitDisabled} onClick={addNewRetailCustomer}>
                        Done
                    </Button>
                    <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" onClick={handleNewCustomerClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showPDFInfo} onHide={handlePDFClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Invoice has been generated successfully !!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex flex-wrap -mx-3 p-3">
                        <a style={{color: '#38B2AC'}} href={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "invoices/" + pdfName} target="_blank">Click here</a>&nbsp;to open invoice.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" onClick={handlePDFClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal dialogClassName="inventory-modal" show={applyProductsToInvoice} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add products to invoice !!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1"
                                    for="grid-first-name">
                                Product
                            </label>
                            <select value={selectedProduct} onChange={e => handleProductSelect(e)} className="block appearance-none w-full bg-grey-200 border border-grey-200 text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey">
                                <option value="0">-- Select Product --</option>
                                {products.map(item => (
                                    <option
                                    key={item.inventoryId}
                                    value={item.inventoryId}
                                    >
                                    {item.isFinalProduct == true ? item.name + " (Final Product) (QTY: " + item.quantity + ")" : item.name + " (Row Material) (QTY: " + item.quantity + ")" }
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1"
                                    for="grid-first-name">
                                Quantity
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                        type="number" placeholder="Quantity" value={productToInvoice.quantity} onChange={handleProductToInvoiceChange} name="quantity"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1"
                                    for="grid-first-name">
                                Selling Price (For 1 Quantity)
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                        type="number" placeholder="Selling Price" value={sellingPrice} onChange={handleProductToInvoiceChange} name="sellingPrice"></input>
                        </div>
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1"
                                    for="grid-first-name">
                                Selling GST (%)
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                        type="number" value={sellingGST} onChange={handleProductToInvoiceChange} placeholder="Product" name="sellingGST"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-4/4 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1"
                                    for="grid-first-name">
                                Remarks
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                        type="text" placeholder="Remarks" value={productToInvoice.remarks} onChange={handleProductToInvoiceChange} name="remarks"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                                type="button" onClick={addProductToInvoice} disabled={addProductToInvoiceDisabled} >
                                Add
                            </button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" onClick={handleClose}>
                        Done
                    </Button>
                    <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="mb-2 border-solid border-gray-300 rounded border shadow-sm w-full">
                <div className="bg-gray-200 px-2 py-3 border-solid border-gray-200 border-b">
                    <b>GENERATE NEW INVOICE</b>
                </div>
                <div className="p-3">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Company
                            </label>
                            <select value={selectedCompany} onChange={e => handleCompanySelect(e)} className="block appearance-none w-full bg-grey-200 border border-grey-200 text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey">
                                <option value="0">-- Select Company --</option>
                                {companies.map(item => (
                                    <option
                                    key={item.companyId}
                                    value={item.companyId}
                                    >
                                    {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {
                            isNewCustomer == false && 
                            <div className="w-full md:w-1/4 px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                    Dealer
                                </label>
                                <select value={selectedDealer} onChange={e => handleDealerSelect(e)} className="block appearance-none w-full bg-grey-200 border border-grey-200 text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey">
                                    <option value="0">-- Select Dealer --</option>
                                    {dealers.map(item => (
                                        <option
                                        key={item.dealerId}
                                        value={item.dealerId}
                                        >
                                        {item.name + " (" + item.city + ")"}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        }
                        {
                            isNewCustomer == true && 
                            <div className="w-full md:w-1/4 px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                    &nbsp;
                                </label>
                                <label className="block tracking-wide text-gray-700 text-xs font-light mb-1">
                                    {retailCustomerDetails.name}&nbsp;({retailCustomerDetails.city})
                                </label>
                            </div>
                        }
                        
                        <div className="w-full md:w-1/5 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                &nbsp;
                            </label>
                            <div className="w-full px-3">
                                <label class="block font-regular text-grey-dark">
                                    <input class="mr-2 leading-tight" type="checkbox" checked={isNewCustomer} onChange={handleNewCustomerChange} name="isNewCustomer"></input>
                                    <span class="text-sm">
                                        Retail Customer
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Payment Due Date
                            </label>
                            <DatePicker className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500" 
                                selected={selectedDueDate}
                                onChange={(date) => setSelectedDueDate(date)}
                                dateFormat="dd/MM/yyyy" />  
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Sale Type
                            </label>
                            <select value={selectedSaleType} onChange={e => handleSaleTypeSelect(e)} className="block appearance-none w-full bg-grey-200 border border-grey-200 text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey">
                                <option value="0">-- Select Sale Type --</option>
                                <option value="State">State</option>
                                <option value="InterState">Inter State</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Payment Type
                            </label>
                            <select value={selectedPaymentType} onChange={e => handlePaymentTypeSelect(e)} className="block appearance-none w-full bg-grey-200 border border-grey-200 text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey">
                                <option value="0">-- Select Payment Type --</option>
                                <option value="Cash">Cash</option>
                                <option value="OnlineBanking">Online Banking</option>
                                <option value="UPI">UPI</option>
                                <option value="Angadia">Angadia</option>
                                <option value="Check">Check</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <button class="flex-shrink-0 border-transparent border-4 text-teal-500 hover:text-teal-700 text-sm py-1 px-2 rounded" type="button" onClick={clickProductsToInvoice}>
                                Add Products
                            </button>
                        </div>
                    </div>
                    {tempSelectedProductsForInvoice.length > 0 &&
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-4/4 px-3 mb-6 md:mb-0 relative">
                            
                                <table className="table-responsive w-full rounded" width="100%">
                                    <thead>
                                        <tr>
                                            <th className="border w-1/4 px-4 py-2" style={{width: '30%', textAlign: 'center'}}>Name</th>
                                            <th className="border w-1/4 px-4 py-2" style={{width: '10%', textAlign: 'center'}}>Quantity</th>
                                            <th className="border w-1/4 px-4 py-2" style={{width: '20%', textAlign: 'center'}}>Remarks</th>
                                            <th className="border w-1/4 px-4 py-2" style={{width: '12%', textAlign: 'center'}}>GST (%)</th>
                                            <th className="border w-1/4 px-4 py-2" style={{width: '15%', textAlign: 'center'}}>GST Value</th>
                                            <th className="border w-1/4 px-4 py-2" style={{width: '10%', textAlign: 'center'}}>Price</th>
                                            <th className="border w-1/4 px-4 py-2" style={{width: '10%', textAlign: 'center'}}>Amount</th>
                                            <th className="border w-1/4 px-4 py-2" style={{width: '5%', textAlign: 'center'}}>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                            {tempSelectedProductsForInvoice.map(item => (
                                                <tr>
                                                    <td className="border px-4 py-2">{item.name}</td>
                                                    <td className="border px-4 py-2" style={{textAlign: 'center'}}>{item.quantity}</td>
                                                    <td className="border px-4 py-2">{item.remarks}</td>
                                                    <td className="border px-4 py-2" style={{textAlign: 'center'}}>{item.sellingGST}</td>
                                                    <td className="border px-4 py-2" style={{textAlign: 'right'}}>{item.gstValue}</td>
                                                    <td className="border px-4 py-2" style={{textAlign: 'right'}}>{item.price}</td>
                                                    <td className="border px-4 py-2" style={{textAlign: 'right'}}>{item.amount}</td>
                                                    <td className="border px-4 py-2">
                                                        <a title="Delete" className="cursor-pointer pl-2" onClick={() => deleteProductFromInvoiceList(item.inventoryId)}><FontAwesomeIcon icon={faTrashAlt} /></a>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            
                        </div>
                    </div>
                }
                    {tempSelectedProductsForInvoice.length > 0 &&
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-4/4 px-3 mb-6 md:mb-0 relative">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                    Gross Amount: <b>{invoiceSummary.grossAmount}</b>
                                </label>
                                {
                                    selectedSaleType == 'State' && 
                                    <div>
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                        cgst: <b>{invoiceSummary.cgst}</b>
                                        </label>
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                            sgst: <b>{invoiceSummary.sgst}</b>
                                        </label>
                                    </div>
                                } 
                                {
                                    selectedSaleType == 'InterState' && 
                                    <div>
                                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                            igst: <b>{invoiceSummary.igst}</b>
                                        </label>
                                    </div>
                                }
                                
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                    Net Amount: <b>{invoiceSummary.netAmount}</b>
                                </label>
                            </div>
                        </div>
                    }
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                                type="button" onClick={generateInvoice} disabled={submitDisabled}>
                                Add
                            </button>
                            <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded ml-2"
                                    type="button" onClick={() => history.push("/invoices")}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default NewInvoice;