import { useParams } from 'react-router-dom';
import axios from "axios";
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

function DealerDetails() {
    const params = useParams();
    const history = useHistory();
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [dealerDetails, setDealerDetails] = useState({name: '', address:'', city: '', state: '', pincode: '', gst:'', isActive: false, showOnContact: false});
    const [dealerCode, setDealerCode] = useState('');
    const [hasDealerCode, setHasDealerCode] = useState(false);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "dealers/" + params.dealerId)
        .then(response => 
            {
                if(response.data.data) {
                    setDealerDetails(response.data.data);
                    if(response.data.data.dealerCode != null && response.data.data.dealerCode.length > 0) {
                        setHasDealerCode(true);
                        console.log(hasDealerCode);
                        setDealerCode(response.data.data.dealerCode);
                    }
                }
                else
                {
                    history.push("/notfound");
                }
            }
        )
        .catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    },[]);

    const handleChange = e => {
        const { name, value, checked } = e.target;
        setDealerDetails(prevState => ({
            ...prevState,
            [name]: e.target.type === "checkbox" ? checked : value 
        }));
    };

    useMemo(() => {
        if (dealerDetails.name != undefined && dealerDetails.name.length > 0 && dealerDetails.phone != undefined && dealerDetails.phone.length > 0) {
            setSubmitDisabled(false);
        }
        else{
           setSubmitDisabled(true);
        }
    }, [dealerDetails]);

    function generateDealerCode() {
        axios.get(process.env.REACT_APP_API_URL + "dealers/generatecode")
        .then(response => 
            {
                if(response.data.data) {
                    setDealerCode(response.data.data.dealerCode);
                }
            }
        )
        .catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    function submitDealerDetails() {
        
        setSubmitDisabled(true);

        axios.post(process.env.REACT_APP_API_URL + "dealers/update", {
            DealerId: dealerDetails.dealerId,
            Name: dealerDetails.name,
            Address: dealerDetails.address,
            Phone: dealerDetails.phone,
            City: dealerDetails.city,
            State: dealerDetails.state,
            PinCode: dealerDetails.pincode,
            GST: dealerDetails.gst,
            DealerCode: dealerCode,
            IsActive: dealerDetails.isActive,
            IsRetail: false,
            ShowOnContact: dealerDetails.showOnContact
          })
          .then((response) => {

            if(response != null && response.data != undefined && response.data.success == true) {
                setDealerDetails(response.data.data);
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            setSubmitDisabled(false);

          }).catch(error => { // your error handling goes here};
            setSubmitDisabled(false);
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            //console.log(error.config);
          });
    }

    return (
        <div className="flex flex-1  flex-col md:flex-row lg:flex-row mx-2">
            <ToastContainer></ToastContainer>
            <div className="mb-2 border-solid border-gray-300 rounded border shadow-sm w-full">
                <div className="bg-gray-200 px-2 py-3 border-solid border-gray-200 border-b">
                    <b>UPDATE DEALER DETAILS</b>
                </div>
                <div className="p-3">
                <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Name
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="text" placeholder="Name" value={dealerDetails.name} onChange={handleChange} name="name"></input>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Phone
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="text" placeholder="Phone" value={dealerDetails.phone} onChange={handleChange} name="phone"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-4/4 px-3 mb-6 md:mb-0 relative">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Address
                            </label>
                            <textarea maxLength="500" className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                placeholder="Address" value={dealerDetails.address} onChange={handleChange} name="address"></textarea>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                City
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="text" placeholder="City" value={dealerDetails.city} onChange={handleChange} name="city"></input>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                State
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="text" placeholder="State" value={dealerDetails.state} onChange={handleChange} name="state"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Pin Code
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="text" placeholder="Pin Code" value={dealerDetails.pincode} onChange={handleChange} name="pincode"></input>
                        </div>
                        <div className="w-full md:w-1/2 px-3 relative">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Dealer Code
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="text" placeholder="Dealer Code" value={dealerCode} onChange={handleChange} name="dealerCode"></input>
                            <FontAwesomeIcon title="Generate Dealer Code" icon={faUserSecret} onClick={() => generateDealerCode()} className={hasDealerCode == true ? "hidden password-eye" : "block password-eye"} />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                    GSTIN
                                </label>
                                <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                        type="text" placeholder="GSTIN" value={dealerDetails.gst} onChange={handleChange} name="gst"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                            <label class="md:w-2/3 block font-regular text-grey-dark">
                                <input class="mr-2 leading-tight" type="checkbox" checked={dealerDetails.showOnContact} onChange={handleChange} name="showOnContact"></input>
                                <span class="text-sm">
                                    Show On Contact Us
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                            <label class="md:w-2/3 block font-regular text-grey-dark">
                                <input class="mr-2 leading-tight" type="checkbox" checked={dealerDetails.isActive} onChange={handleChange} name="isActive"></input>
                                <span class="text-sm">
                                    Active
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                                type="button" onClick={submitDealerDetails} disabled={submitDisabled}>
                                Update
                            </button>
                            <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded ml-2"
                                    type="button" onClick={() => history.push("/dealers")}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DealerDetails;