import Header from '../../components/header/header.js';
import Sidebar from '../../components/sidebar/sidebar.js';
import Contacts from '../../components/contacts/contacts.js';
import Landing from '../../components/landing/landing.js';
import NewProduct from '../../components/products/new-product.js';
import AllProducts from '../../components/products/all-products.js';
import NewAdmin from '../../components/admins/add-admin.js';
import AllAdmins from '../../components/admins/all-admins.js';
import AdminDetails from '../../components/admins/admin-details.js';
import ProductDetails from '../../components/products/product-details.js';
import ProductImages from '../../components/products/product-images.js';
import DealerDetails from '../../components/dealers/dealer-details.js';
import AllDealers from '../../components/dealers/all-dealers.js';
import NewDealer from '../../components/dealers/add-dealer.js';
import ProductEnquiries from '../../components/productenquiries/productenquiries.js';
import NotFound from '../../components/notfound/notfound.js';
import WebUtility from '../../components/webutility/webutility.js';
import NewInventory from '../../components/inventory/new-inventory.js';
import AllInventories from '../../components/inventory/all-inventories.js';
import InventoryDetails from '../../components/inventory/inventory-details.js';
import NewInvoice from '../../components/invoice/new-invoice.js';
import AllInvoices from '../../components/invoice/all-invoice.js';
import AllRetailCustomers from '../../components/dealers/all-retail-customers.js';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function Dashboard() {
    return (
        <div class="mx-auto bg-grey-lightest">
            <div class="min-h-screen flex flex-col">
                <Header></Header>
                <div class="flex flex-1">
                    <Sidebar></Sidebar>
                    <main className="bg-white-500 flex-1 p-3 overflow-hidden">
                        <Switch>
                            <Route path="/" exact component={Landing}></Route>
                            <Route path="/contacts" exact component={Contacts}></Route>
                            <Route path="/productenquiries" exact component={ProductEnquiries}></Route>
                            <Route path="/webutility" exact component={WebUtility}></Route>
                            <Route path="/productenquiries/product/:productId" exact component={ProductEnquiries}></Route>
                            <Route path="/products" exact component={AllProducts}></Route>
                            <Route path="/addproduct" exact component={NewProduct}></Route>
                            <Route path="/admins" exact component={AllAdmins}></Route>
                            <Route path="/dealers" exact component={AllDealers}></Route>
                            <Route path="/addadmin" exact component={NewAdmin}></Route>
                            <Route path="/adddealer" exact component={NewDealer}></Route>
                            <Route path="/dealerdetails/:dealerId" exact component={DealerDetails}></Route>
                            <Route path="/admindetails/:adminId" exact component={AdminDetails}></Route>
                            <Route path="/productdetails/:productId" exact component={ProductDetails}></Route>
                            <Route path="/productimages/:productId" exact component={ProductImages}></Route>
                            <Route path="/inventories" exact component={AllInventories}></Route>
                            <Route path="/addinventory" exact component={NewInventory}></Route>
                            <Route path="/inventorydetails/:inventoryId" exact component={InventoryDetails}></Route>
                            <Route path="/generateinvoice" exact component={NewInvoice}></Route>
                            <Route path="/invoices" exact component={AllInvoices}></Route>
                            <Route path="/retailcustomers" exact component={AllRetailCustomers}></Route>
                            <Route component={NotFound}></Route>
                        </Switch>
                    </main>
                    
                </div>
            </div>
        </div>
    );
}
export default Dashboard;