import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, dateFilter  } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../assets/styles/pagination.css';
import axios from "axios";
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes, faCheck, faTrashAlt, faImages, faUserTie } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

function AllProducts() {

    const [productsData, setProductsData] = useState([]);
    const [productId, setProductId] = useState(0);
    const [deleteDisabled, setDeleteDisabled] = useState(false);

    const handleClose = () => setProductId(0);

    useEffect(() => {
        loadProductsRecords();
    },[]);

    function loadProductsRecords() {
        axios.get(process.env.REACT_APP_API_URL + "products")
        .then(response => 
            {
                setProductsData(response.data.data);
            }
        )
        .catch(error => {
            if (error.response) {
                if(error.response.data.message != undefined){
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    function dateColumnFormatter(cell, row) {
        return <Moment format="DD/MM/YYYY">{row.createdDate}</Moment>;
    }

    function activeColumnFormatter(cell, row) {
        if (row.isActive) {
            return <FontAwesomeIcon title="Active" icon={faCheck} />;
        }
        else{
            return <FontAwesomeIcon title="InActive" icon={faTimes} />;
        }
    }

    function trendingColumnFormatter(cell, row) {
        if (row.isTrending) {
            return <FontAwesomeIcon title="Trending" icon={faCheck} />;
        }
        else{
            return <FontAwesomeIcon title="Not in Trending" icon={faTimes} />;
        }
    }

    function recommendedColumnFormatter(cell, row) {
        if (row.isRecommended) {
            return <FontAwesomeIcon title="Recommended" icon={faCheck} />;
        }
        else{
            return <FontAwesomeIcon title="Not in Recommended" icon={faTimes} />;
        }
    }

    function editColumnFormatter(cell, row) {
        return (
            <div>
                <Link title="Update Product Details" to={'/productdetails/'+ row.productId }><FontAwesomeIcon icon={faEdit} /></Link>
                <Link title="Upload Product Images" className="pl-2" to={'/productimages/'+ row.productId }><FontAwesomeIcon icon={faImages} /></Link>
                <a title="Delete Product" className="cursor-pointer pl-2" onClick={() => deleteProductConfirmation(row.productId)}><FontAwesomeIcon icon={faTrashAlt} /></a>
                <Link title="Product Enquiries" className="pl-2" to={'/productenquiries/product/'+ row.productId }><FontAwesomeIcon icon={faUserTie} /></Link>
            </div>
        );
    }

    function deleteProductConfirmation(id) {
        setProductId(id);
    }

    function deleteProductRecord() {
        setDeleteDisabled(true);

        axios.post(process.env.REACT_APP_API_URL + "products/delete/" + productId, null)
          .then((response) => {

            if(response != null && response.data.success == true) {
                setProductId(0);
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                loadProductsRecords();
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            setDeleteDisabled(false);

          }).catch(error => { // your error handling goes here};
            setDeleteDisabled(false);
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
          });
    }

    const options = {
        paginationSize: 10,
        pageStartIndex: 1,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        hideSizePerPage: true, // Hide the sizePerPage dropdown always
        hidePageListOnlyOnePage: true // Hide the pagination list when only one page
      };

      const columns = [{
        dataField: 'productId',
        text: 'Product Id',
        hidden: true
      }, {
        dataField: 'name',
        text: 'Name',
        sort: true,
        filter: textFilter()
      }, {
        dataField: 'retailPrice',
        text: 'Retail Price',
        sort: true,
        filter: textFilter()
      }, {
        dataField: 'dealerPrice',
        text: 'Dealer Price',
        sort: true,
        filter: textFilter()
      }, {
        dataField: 'createdDate',
        text: 'Created Date',
        sort: true,
        formatter: dateColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { width: '170px', textAlign: 'center' };
        }
      },{
        dataField: 'isTrending',
        text: 'Trending',
        sort: true,
        align: 'center',
        formatter: trendingColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { width: '120px', textAlign: 'center' };
        }
      },{
        dataField: 'isRecommended',
        text: 'Recommended',
        sort: true,
        align: 'center',
        formatter: recommendedColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { width: '170px', textAlign: 'center' };
        }
      }, {
        dataField: 'isActive',
        text: 'Active',
        sort: true,
        align: 'center',
        formatter: activeColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { width: '100px', textAlign: 'center' };
        }
      }, {
        dataField: '',
        text: 'Action',
        align: 'center',
        formatter: editColumnFormatter,
        headerStyle: (colum, colIndex) => {
            return { width: '150px', textAlign: 'center' };
        }
      }];

    return (
        <div>
            <ToastContainer></ToastContainer>
            <Modal show={productId > 0} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Confirmation !!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this record?
                </Modal.Body>
                <Modal.Footer>
                <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" disabled={deleteDisabled} onClick={deleteProductRecord}>
                    Yes
                </Button>
                <Button className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded" onClick={handleClose}>
                    No
                </Button>
                </Modal.Footer>
            </Modal>
            <div class="bg-gray-300 px-2 py-3 border-solid border-gray-400 border-b">
                <b>PRODUCTS</b>
            </div>
            <div class="p-3">
                <BootstrapTable keyField='id' data={productsData} columns={columns} pagination={ paginationFactory(options) } filter={ filterFactory() } filterPosition="top"  />
            </div>
        </div>
    );
}
export default AllProducts;