import { useParams } from 'react-router-dom';
import axios from "axios";
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';

function ProductDetails() {
    const params = useParams();
    const history = useHistory();
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [productDetails, setProductDetails] = useState({name: '', description: '', longDescription: '', deliveryReturn: '', dealerPrice: null, isActive: false, isTrending: false, isRecommended: false});
    const editorRef = useRef(null);
    const [productLongDescription, setproductLongDescription] = useState('');
    const editorRefDeliveryReturn = useRef(null);
    const [deliveryReturn, setDeliveryReturn] = useState('');

    const handleChange = e => {
        const { name, value, checked } = e.target;

        setProductDetails(prevState => ({
            ...prevState,
            [name]: e.target.type === "checkbox" ? checked : value 
        }));
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "products/" + params.productId)
        .then(response => 
            {
                if(response.data.data) {
                    setProductDetails(response.data.data);
                    setproductLongDescription(response.data.data.longDescription);
                    setDeliveryReturn(response.data.data.deliveryReturn);
                }
                else
                {
                    history.push("/notfound");
                }
            }
        )
        .catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    },[]);

    useMemo(() => {
        if (productDetails.name != undefined && productDetails.name.length > 0 && productLongDescription != undefined && productLongDescription.length > 0 && deliveryReturn != undefined && deliveryReturn.length > 0) {
            setSubmitDisabled(false);
        }
        else{
           setSubmitDisabled(true);
        }
    }, [productDetails, productLongDescription, deliveryReturn]);

    function submitProductDetails() {
        setSubmitDisabled(true);

        axios.post(process.env.REACT_APP_API_URL + "products/update", {
            ProductId: productDetails.productId,
            Name: productDetails.name,
            Description: productDetails.description,
            LongDescription: productLongDescription,
            DeliveryReturn: deliveryReturn,
            DealerPrice: parseInt(productDetails.dealerPrice),
            RetailPrice: parseInt(productDetails.retailPrice),
            IsTrending: productDetails.isTrending,
            IsRecommended: productDetails.isRecommended,
            IsActive: productDetails.isActive
          })
          .then((response) => {

            if(response != null && response.data != undefined && response.data.success == true) {
                setProductDetails(response.data.data);
                setproductLongDescription(response.data.data.longDescription);
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

            setSubmitDisabled(false);

          }).catch(error => { // your error handling goes here};
            setSubmitDisabled(false);
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            //console.log(error.config);
          });
    }

    return (
        <div className="flex flex-1  flex-col md:flex-row lg:flex-row mx-2">
            <ToastContainer></ToastContainer>
            <div className="mb-2 border-solid border-gray-300 rounded border shadow-sm w-full">
                <div className="bg-gray-200 px-2 py-3 border-solid border-gray-200 border-b">
                    <b>UPDATE PRODUCT DETAILS</b>
                </div>
                <div className="p-3">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 relative">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Name
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="text" maxLength="500" placeholder="Name" value={productDetails.name} onChange={handleChange} name="name"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Retail Price
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="number" placeholder="Retail Price" value={productDetails.retailPrice} onChange={handleChange} name="retailPrice"></input>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Dealer Price
                            </label>
                            <input className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                    type="number" placeholder="Dealer Price" value={productDetails.dealerPrice} onChange={handleChange} name="dealerPrice"></input>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-4/4 px-3 mb-6 md:mb-0 relative">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-light mb-1">
                                Short Description
                            </label>
                            <textarea maxLength="500" className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white-500"
                                placeholder="Short Description" value={productDetails.description} onChange={handleChange} name="description"></textarea>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-4/4 px-3 mb-6 md:mb-0 relative">
                            <label class="block uppercase tracking-wide text-grey-darker text-xs font-light mb-1">
                                Long Description
                            </label>
                            
                            <Editor
                                onInit={(evt, editor) => editorRef.current = editor}
                                value={productLongDescription}
                                onEditorChange={(newText) => setproductLongDescription(newText)}
                                apiKey={process.env.REACT_APP_EDITOR_KEY}
                                init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full md:w-4/4 px-3 mb-6 md:mb-0 relative">
                            <label class="block uppercase tracking-wide text-grey-darker text-xs font-light mb-1">
                                Delivery / Return Policy
                            </label>
                            
                            <Editor
                                onInit={(evt, editor) => editorRefDeliveryReturn.current = editor}
                                value={deliveryReturn}
                                onEditorChange={(newText) => setDeliveryReturn(newText)}
                                apiKey={process.env.REACT_APP_EDITOR_KEY}
                                init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                            <label class="md:w-2/3 block font-regular text-grey-dark">
                                <input class="mr-2 leading-tight" type="checkbox" checked={productDetails.isTrending} onChange={handleChange} name="isTrending"></input>
                                <span class="text-sm">
                                Trending
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                            <label class="md:w-2/3 block font-regular text-grey-dark">
                                <input class="mr-2 leading-tight" type="checkbox" checked={productDetails.isRecommended} onChange={handleChange} name="isRecommended"></input>
                                <span class="text-sm">
                                    Recommended
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                            <label class="md:w-2/3 block font-regular text-grey-dark">
                                <input class="mr-2 leading-tight" type="checkbox" checked={productDetails.isActive} onChange={handleChange} name="isActive"></input>
                                <span class="text-sm">
                                    Active
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                                    type="button" onClick={submitProductDetails} disabled={submitDisabled}>
                                Update
                            </button>
                            <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded ml-2"
                                    type="button" onClick={() => history.push("/products")}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ProductDetails;