import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faFeatherAlt, faFileContract, faAngleRight, faAngleDown, faUserShield, faUsers, faCogs, faBoxes } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

function Sidebar() {
    return (
        <aside id="sidebar" className="bg-side-nav w-1/2 md:w-1/6 lg:w-1/6 border-r border-side-nav hidden md:block lg:block">
                <ul className="list-reset flex flex-col">
                    <li className=" w-full h-full py-3 px-2 border-b border-light-border bg-white">
                        <Link to="/" className="font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                            <FontAwesomeIcon icon={faTachometerAlt} className="float-left mx-2 sidebar-icon" />
                            Dashboard
                            <span><FontAwesomeIcon icon={faAngleRight} className="float-right" /></span>
                        </Link>
                    </li>
                    <li className="w-full h-full py-3 px-2 border-b border-light-border">
                        <Link to="/contacts" className="font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                            <FontAwesomeIcon icon={faFileContract} className="float-left mx-2 sidebar-icon" />
                            Contact Enquiry
                            <span>
                            <FontAwesomeIcon icon={faAngleRight} className="float-right" />
                            </span>
                        </Link>
                    </li>
                    <li className="w-full h-full py-3 px-2 border-b border-light-border">
                        <Link to="/productenquiries" className="font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                            <FontAwesomeIcon icon={faFileContract} className="float-left mx-2 sidebar-icon" />
                            Product Enquiry
                            <span>
                            <FontAwesomeIcon icon={faAngleRight} className="float-right" />
                            </span>
                        </Link>
                    </li>
                    <li className=" w-full h-full py-3 px-2 border-b border-light-border">
                        <Link to="/webutility" className="font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                            <FontAwesomeIcon icon={faCogs} className="float-left mx-2 sidebar-icon" />
                            Web Utility
                            <span><FontAwesomeIcon icon={faAngleRight} className="float-right" /></span>
                        </Link>
                    </li>
                    <li className="w-full h-full py-3 px-2">
                        <a
                           className="font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                            <FontAwesomeIcon icon={faFeatherAlt} className="float-left mx-2 sidebar-icon" />
                            Products (Website Only)
                            <span><span><FontAwesomeIcon icon={faAngleDown} className="float-right" /></span></span>
                        </a>
                        <ul className="list-reset -mx-2 bg-white-medium-dark">
                            <li className="border-t mt-2 border-light-border w-full h-full px-2 py-3">
                                <Link to="/addproduct" className="mx-4 font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                                    Add New Product
                                    <span><FontAwesomeIcon icon={faAngleRight} className="float-right" /></span>
                                </Link>
                            </li>
                            <li className="border-t border-light-border w-full h-full px-2 py-3">
                                <Link to="/products" className="mx-4 font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                                    All Products
                                    <span><FontAwesomeIcon icon={faAngleRight} className="float-right" /></span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="w-full h-full py-3 px-2">
                        <a
                           className="font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                            <FontAwesomeIcon icon={faUsers} className="float-left mx-2 sidebar-icon" />
                            Manage Dealers
                            <span><span><FontAwesomeIcon icon={faAngleDown} className="float-right" /></span></span>
                        </a>
                        <ul className="list-reset -mx-2 bg-white-medium-dark">
                            <li className="border-t mt-2 border-light-border w-full h-full px-2 py-3">
                                <Link to="/adddealer" className="mx-4 font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                                    Add New Dealer
                                    <span><FontAwesomeIcon icon={faAngleRight} className="float-right" /></span>
                                </Link>
                            </li>
                            <li className="border-t border-light-border w-full h-full px-2 py-3">
                                <Link to="/dealers" className="mx-4 font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                                    All Dealers
                                    <span><FontAwesomeIcon icon={faAngleRight} className="float-right" /></span>
                                </Link>
                            </li>
                            <li className="border-t border-light-border w-full h-full px-2 py-3">
                                <Link to="/retailcustomers" className="mx-4 font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                                    All Retail Customers
                                    <span><FontAwesomeIcon icon={faAngleRight} className="float-right" /></span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="w-full h-full py-3 px-2">
                        <a
                           className="font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                            <FontAwesomeIcon icon={faUserShield} className="float-left mx-2 sidebar-icon" />
                            Manage Admins
                            <span><span><FontAwesomeIcon icon={faAngleDown} className="float-right" /></span></span>
                        </a>
                        <ul className="list-reset -mx-2 bg-white-medium-dark">
                            <li className="border-t mt-2 border-light-border w-full h-full px-2 py-3">
                                <Link to="/addadmin" className="mx-4 font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                                    Add New Admin User
                                    <span><FontAwesomeIcon icon={faAngleRight} className="float-right" /></span>
                                </Link>
                            </li>
                            <li className="border-t border-light-border w-full h-full px-2 py-3">
                                <Link to="/admins" className="mx-4 font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                                    All Admin Users
                                    <span><FontAwesomeIcon icon={faAngleRight} className="float-right" /></span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="w-full h-full py-3 px-2">
                        <a
                           className="font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                            <FontAwesomeIcon icon={faBoxes} className="float-left mx-2 sidebar-icon" />
                            Stock Management
                            <span><span><FontAwesomeIcon icon={faAngleDown} className="float-right" /></span></span>
                        </a>
                        <ul className="list-reset -mx-2 bg-white-medium-dark">
                            <li className="border-t mt-2 border-light-border w-full h-full px-2 py-3">
                                <Link to="/addinventory" className="mx-4 font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                                    Add New Stock
                                    <span><FontAwesomeIcon icon={faAngleRight} className="float-right" /></span>
                                </Link>
                            </li>
                            <li className="border-t border-light-border w-full h-full px-2 py-3">
                                <Link to="/inventories" className="mx-4 font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                                    All Stocks
                                    <span><FontAwesomeIcon icon={faAngleRight} className="float-right" /></span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="w-full h-full py-3 px-2">
                        <a
                           className="font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                            <FontAwesomeIcon icon={faBoxes} className="float-left mx-2 sidebar-icon" />
                            Invoice
                            <span><span><FontAwesomeIcon icon={faAngleDown} className="float-right" /></span></span>
                        </a>
                        <ul className="list-reset -mx-2 bg-white-medium-dark">
                            <li className="border-t mt-2 border-light-border w-full h-full px-2 py-3">
                                <Link to="/generateinvoice" className="mx-4 font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                                    Generate New Invoice
                                    <span><FontAwesomeIcon icon={faAngleRight} className="float-right" /></span>
                                </Link>
                            </li>
                            <li className="border-t border-light-border w-full h-full px-2 py-3">
                                <Link to="/invoices" className="mx-4 font-sans font-hairline hover:font-normal text-sm text-nav-item no-underline">
                                    All Invoices
                                    <span><FontAwesomeIcon icon={faAngleRight} className="float-right" /></span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                </ul>

            </aside>
    );
}
export default Sidebar;